<template>
    <div class="content d-flex justify-sm-center align-center">
      <div class="card_box text-center">
        
        <h3 class="my-4 title_text">Account Added <v-icon size="30" color="#023E7D">mdi-checkbox-marked-circle-outline</v-icon></h3>
        <p>
          Thanks a bunch for completing your stripe account, We are now able to process payment directly to this account, We really appreciate you giving us a moment of your time today. Thanks for being you.
        </p>
        <v-progress-circular
          class="my-2"
          :size="20"
          :width="2"
          :indeterminate="loading"
          color="#023E7D"
        ></v-progress-circular>
        <p class="text_caption">Powered by <b>Stripe</b></p>
      </div>
    </div>
</template>

<script>
    export default {

        components: {
            
        },
        mounted: function () {
          this.accountId = this.$route.query.account_id;
          this.onFinish()
        },
        data () {
          return {
            accountId: '',
            loading: false,
          };
        },
        methods: {
          onFinish () {
            this.loading = true
            this.$store.dispatch('updateStripe', {
              account_id: this.accountId
            })
            .then(() => {
              setTimeout(() => {
                this.loading = false
                this.closeTab()
              }, 3000);
            })
            .catch((error) => {
              this.loading = false
              this.snackbar = true
              this.message = error.response.data.error
              this.bgColor = 'error'
            })
          },

          closeTab() {
            window.close();
          }
        },
        computed: {
            
        }
    };
</script>

<style scope>
.content {
    height: 100vh
}
.card_box {
    max-width: 550px;
}
.title_text {
  font-size: 40px;
  font-weight: 500;
  color: #023E7D;
}
.text_caption {
    margin-top: 20px;
    font-size: 14px;
    color: grey;
}
</style>