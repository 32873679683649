<template>
    <div>
        <v-container style="height: 400px;">
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
            >
                <v-col
                class="text-subtitle-1 text-center"
                cols="12"
                >
                Logging out
                </v-col>
                <v-col cols="3">
                <v-progress-linear
                    color="#023E7D"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        mounted() {
            // this.$store.dispatch('destroyToken')
            // this.$store.dispatch('clearDetails')
            setTimeout(() => {
                this.$router.push('/')
            }, 2000);
        }
    }
</script>