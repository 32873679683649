<template>
    <v-dialog v-model="dialog" max-width="300" persistent>
      <v-card>
        <v-card-title>
          Select Time
        </v-card-title>
        <v-card-subtitle>
          <span>Boost time is limited to hours alone.</span>
        </v-card-subtitle>
        <v-card-text class="d-flex justify-start align-center">
          <div>
            <v-text-field
              v-model="localTimeHour"
              label="Hour"
              placeholder="00"
              :hide-details="true"
              maxlength="2"
              filled
              class="some-style"
              @input="validateHour"
            ></v-text-field>
          </div>
          <div class="mx-1 time_demacation">:</div>
          <div>
            <v-text-field
              v-model="localTimeMinute"
              label="Minute"
              placeholder="00"
              :hide-details="true"
              maxlength="2"
              readonly
              filled
              class="some-style"
              @input="validateMinute"
            ></v-text-field>
          </div>
          <div class="ms-2 pm_am-box">
            <v-btn :color="period === 'AM' ? '#023E7D' : '#F3F3F3'" :dark="period === 'AM'" elevation="0" small @click="togglePeriod('AM')">AM</v-btn>
            <br />
            <v-btn :color="period === 'PM' ? '#023E7D' : '#F3F3F3'" :dark="period === 'PM'" elevation="0" small @click="togglePeriod('PM')">PM</v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="me-2">
          <v-spacer></v-spacer>
          <v-btn color="#023E7D" small text @click="closeDialog">close</v-btn>
          <v-btn color="#023E7D" small text @click="confirmTime">ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
  
<script>
  export default {
    props: {
      value: Boolean,
      timeHour: String,
      timeMinute: String
    },
    data() {
      return {
        dialog: this.value,
        localTimeHour: this.timeHour || '',
        localTimeMinute: this.timeMinute || '',
        period: 'AM'
      };
    },
    watch: {
        value(val) {
            this.dialog = val;
        },
        dialog(val) {
            this.$emit('input', val);
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },

        confirmTime() {
            const formattedHour = this.localTimeHour.padStart(2, '0');
            const formattedMinute = this.localTimeMinute.padStart(2, '0');
            const time = `${formattedHour}:${formattedMinute} ${this.period}`;
            this.$emit('time-selected', time);
            this.dialog = false;
        },

        togglePeriod(period) {
            this.period = period;
        },

        validateHour() {
            let hour = parseInt(this.localTimeHour, 10);
            if (isNaN(hour) || hour < 1) {
                hour = 1;
            } else if (hour > 12) {
                hour = 12;
            }
            this.localTimeHour = hour.toString().padStart(2, '0');
        },

        validateMinute() {
            let minute = parseInt(this.localTimeMinute, 10);
            if (isNaN(minute) || minute < 0) {
                minute = 0;
            } else if (minute > 59) {
                minute = 59;
            }
            this.localTimeMinute = minute.toString().padStart(2, '0');
        }
    }
  };
</script>
  
<style scoped>
.time_demacation {
    font-size: 30px;
    font-weight: 900;
}
.pm_am-box {
    border: 1px solid #F3F3F3;
}
.some-style >>> .v-input__slot::before {
    border-style: none !important;
}
</style>
  