<template>
  <div>
    <v-app-bar
      color="white"
      flat
      dense
    >
      <v-app-bar-nav-icon color="#2861A6" class="d-flex d-sm-flex d-md-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="me-2 text-capitalize user_text">
        {{userData.first_name}}
      </v-toolbar-title>

      <v-avatar size="30" color="#2861A6" class="me-2">
        <img
          v-if="userData?.image_url != 'img-cloud315r36bdhgevfg'"
          :src="userData?.image_url"
          :alt="userData.first_name"
        >
        <span v-else class="white--text text-uppercase initials_text">{{initialsAvatar}}</span>
      </v-avatar>

      <Notification />
      <Chat />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      color="#023E7D"
      class="d-flex d-sm-flex d-md-none"
    >
      <v-list-item class="px-2 mb-6">
      <v-list-item-avatar>
        <v-img src="../../assets/legallync-icon.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-title class="banner font-weight-bold">legallync</v-list-item-title>
    </v-list-item>

    

    <v-list flat>
      <v-list-item-group
        v-model="selectedItem"
        active-class="link"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          router :to="item.route"
          link
          active-class="link"
        >
          <v-list-item-icon>
            <v-icon class="link_icon" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="bottom_link font-weight-medium" v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import Notification from '../../components/Notification.vue'
  import Chat from '../../components/Chat.vue'

  export default {
    components: {
      Notification,
      Chat
    },

    data () {
      return {
        drawer: false,
        selectedItem: 0,
        items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard-outline', route: '/client' },
          { title: 'Cases', icon: 'mdi-briefcase-outline', route: '/client/cases' },
          { title: 'Appointments', icon: 'mdi-calendar-blank-outline', route: '/client/appointments' },
          { title: 'Settings', icon: 'mdi-cog-outline', route: '/client/settings' },
          { title: 'Logout', icon: 'mdi-logout-variant', route: '/logout' },
        ],
      }
    },

    computed: {
      userData() {
        return this.$store.state.userData.user;
      },

      initialsAvatar() {
        var firstInitial = this.$store.state.userData.user.first_name[0]
        var lastInitial = this.$store.state.userData.user.last_name[0]
        return firstInitial + lastInitial;
      },
    }
  }
</script>

<style scoped>
  .user_text {
    color: #023E7D;
    font-size: 12px;
  }

  .banner {
    color: #FFFFFF;
  }

  .link_icon {
    color: #859DBE;
  }
  
  .link {
    color: #FFFFFF !important;
  }

  .bottom_link {
    color: #859DBE;
  }

  .initials_text {
    font-size: 14px;
    font-weight: bold;
  }

</style>