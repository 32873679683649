<template>
  <div>
    <v-btn
        class="ps-4 mb-2"
        color="#023E7D"
        dark
        depressed
        rounded
        @click="dialog = true"
    >
        Request consult
        <v-icon
        right
        dark
        >
            mdi-plus-circle-outline
        </v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="700"
      scrollable
    >
      <v-card>
        <div class="title_bg">
            <v-card-title class="card_title font-weight-bold">
            Request Consultation
            </v-card-title>
        </div>
        <v-card-text class="py-4" style="height: 400px;">
            <span>What case do want to create a consultation for?</span>
            <v-list class="my-1 py-0">
                <v-list-item 
                v-for="(item, i) in allCase"
                :key="i"
                class="px-1"
                @click="selectItem(item, i)"
                >
                    <v-list-item-avatar color="#DCE8F8">
                        <v-icon color="#C3D6EF" dark>
                        mdi-briefcase
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex justify-space-between align-center">
                            <span>{{item.case_name}}</span>
                            <div class="d-flex align-center">
                                <span v-show="item.status == 'open'" class="attorney_list-text">{{item.potential_attorneys_id.length}} Prospective Attorney<span v-show="item.potential_attorneys_id.length > 1">s</span></span>
                                <span v-show="item.status == 'retained'" class="me-8 attorney_list-text">Retained</span>
                                <span v-show="item.status == 'matched'" class="me-8">{{item.match_requests.length}} Matched</span>
                                <v-progress-circular
                                    v-show="loadingState[i]"
                                    :indeterminate="loadingState[i]"
                                    :size="15"
                                    :width="2"
                                    color="primary"
                                    class="ms-4 me-2"
                                    ></v-progress-circular>
                                </div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- book appointments -->
    <v-dialog
        v-model="bookDialog"
        max-width="650"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Book Consultation
                </v-card-title>
            </div>
            <v-card-text class="mt-8">
                <div class="mb-2 attorney_card-box d-flex align-center">
                    <v-avatar rounded size="45" class="me-4" color="#DCE8F8">
                        <v-icon color="#C3D6EF" dark>
                            mdi-account-outline
                        </v-icon>
                    </v-avatar>
                    <div>
                        <h4 class="attorney_list-title">{{attorneyDetails.first_name}} {{attorneyDetails.last_name}}</h4>
                        <span class="attorney_list-subtitle">
                            <span
                            class="text-capitalize">
                                {{attorneyprofile.attorney_type | commaSeparated}}
                            </span>
                        </span>
                    </div>
                </div>
                <p class="mb-8 mt-4">Please pick any of the available days on the calender, note the days set by the attorney.</p>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                <v-row>
                    <v-col lg="6">
                        <v-date-picker
                        v-if="allowedLoader"
                        color="#023E7D"
                        v-model="availableDate"
                        :allowed-dates="allowedDates"
                        :to-allowed-dates="(val) => toAllowedDates(val, index)"
                        :picker-date.sync="pickerDateNext"
                        full-width
                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        @change="getAvailableTimes"
                        ></v-date-picker>
                        <v-card
                        v-else
                        flat
                        height="300"
                        class="d-flex justify-center align-center"
                        >
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </v-card>
                    </v-col>
                    <v-col lg="6">
                        <h4>Pick a time</h4>
                        <span>Set time(s) for the selected date(s) on the calendar</span>
                        <div class="py-2">
                            <v-select
                            v-model="selectedTime"
                            :items="availableTimes"
                            :item-text="item => item.from+' - '+ item.to"
                            :rules="[rules.objRequired]"
                            filled
                            label="Available Times"
                            return-object
                            dense
                            :loading="loading"
                            :disabled="availableDate != '' ? false : true"
                            class="some-style"
                            ></v-select>
                        </div>
                        <div class="text-end">
                            <v-btn
                            color="#023E7D"
                            class="stepper_btn"
                            depressed
                            :loading="bookAppointmentloader"
                            @click="bookAppointment"
                            >
                            Book Consultation
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        </v-dialog>

    <!-- snackbar -->
    <v-snackbar
    v-model="snackbar"
    :color="bgColor"
    top
    center
    >
    {{ message }}
    <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
            Close
        </v-btn>
    </template>
    </v-snackbar>

  </div>
</template>

<script>
import moment from 'moment'

  export default {
    props: {
        attorneyItems: Object,
    },

    data () {
      return {
        dialog: false,
        e1: 1,
        selectedListItem: null,
        menu: false,
        date: '',
        checkbox: false,
        checkbox2: false,
        loading: false,
        time: {},
        caseId: '',
        attorneyId: '',
        availability: [],
        daysAvailable: [],
        selectedCaseStatus: '',
        matchRetainLoader: false,
        retainAttorneysList: [],
        matchAttorneysList: [],
        snackbar: false,
        message: '',
        bgColor: '',
        attorneySelected: false,
        attorneySelectedLoader: false,
        pickerDateNext: null,
        availableTimes: [],
        bookAppointmentloader: false,
        allowedLoader: true,
        allowedDateList: [],
        availableDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        selectedTime: {},
        bookDialog: false,
        attorneyDetails: {},
        attorneyprofile: {},
        valid: true,
        rules: {
          required: value => !!value || 'Required.',
          objRequired: v => Boolean(Object.keys(v || {})[0]) || "Required.",
        },
        loadingState: {}
      }
    },

    watch: {
        async pickerDateNext (newval) {
            this.allowedLoader = false
            let separatedItem = newval.split('-');
            let selectedYear = separatedItem[0]
            let selectedMonth = separatedItem[1]
            this.loading = true;
            await this.$store.dispatch('getBookableDates', {
                year: selectedYear,
                month: selectedMonth,
                attorneyId: this.attorneyDetails._id
            })
            .then((response) => {
                this.loading = false
                this.allowedDateList = response.data.data
                console.log('date we allow')
                console.log(this.allowedDateList)
                this.allowedLoader = true
                this.getAvailableTimes()
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
    },

    methods: {
        async getAvailableTimes() {
            let formattedDate = moment(this.availableDate, "YYYY-MM-DD").format("MM-DD-YYYY")
            this.loading = true;
            await this.$store.dispatch('getAvailableTimes', {
                date: formattedDate,
                attorneyId: this.attorneyDetails._id
            })
            .then((response) => {
                this.loading = false
                this.availableTimes = response.data.data
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        allowedDates(val) {
            const dDates = this.allowedDateList.map(date => moment(date, "MM-DD-YYYY").format("YYYY-MM-DD"));
            return dDates.includes(val) ? false : true
        },

        bookAppointment() {
            let formstatus = this.$refs.form.validate()
            if(formstatus != true) return

            this.bookAppointmentloader = true;
            this.$store.dispatch('bookAppointment', {
                date: new Date(this.availableDate),
                from: moment(this.selectedTime.from, ["h:mm A"]).format("HH:mm"),
                to: moment(this.selectedTime.to, ["h:mm A"]).format("HH:mm"),
                attorney_id: this.attorneyDetails._id,
                case_id: this.caseId,
                status: 'booked',
                is_consultant: 1
            })
            .then((response) => {
                this.bookAppointmentloader = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.bookDialog = false
                this.$store.dispatch('getClientAppointments')
            })
            .catch((error) => {
                this.bookAppointmentloader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        selectItem(item, index) {
            this.$set(this.loadingState, index, true);

            console.log('selectItem')
            console.log(item)
            console.log()
            this.caseId = item._id
            this.attorneyDetails = this.attorneyItems.user_id
            this.attorneyprofile = this.attorneyItems

            this.$store.dispatch('getAttorneyAvailability', this.attorneyDetails._id)
            .then((response) => {
                this.$set(this.loadingState, index, false);
                this.availability = response.data.data
                this.bookDialog = true
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })


            // this.$store.dispatch('getAttorneyAvailability', this.attorneyItems._id)
            // .then((response) => {
            //     console.log(response)
            //     this.availability = response.data.data
            // })
            // .catch((error) => {
            //     this.snackbar = true
            //     this.message = error.response.data.error
            //     this.bgColor = 'error'
            // })
                
        },

        selectedAttorney(item) {
            console.log(item)
            

            this.attorneySelectedLoader = true
            
        },

        // getAttorneyProfile(item) {
        //     this.$store.dispatch('getAttorneyById', item.retained_attorney_id)
        //     .then((response) => {
        //         this.retainAttorneyDetail.attorney = response.data.attorney
        //         this.retainAttorneyDetail.attorney_profile = response.data.attorney_profile
        //         this.retainAttorneyDetail.case_id = item._id,
        //         this.retainAttorneyDetail.client_id = item.client_id
        //         console.log(this.retainAttorneyDetail)
        //     })
        //     .catch(() => {

        //     })
        // },

        checkDay() {
            var date = new Date(this.date)
            var dayIndex = date.getDay()
            if(dayIndex == 0) {
                this.daysAvailable = this.availability.filter( i => 'Sunday'.includes( i.day ) )
            } else if(dayIndex == 1) {
                this.daysAvailable = this.availability.filter( i => 'Monday'.includes( i.day ) )
            } else if(dayIndex == 2) {
                this.daysAvailable = this.availability.filter( i => 'Tuesday'.includes( i.day ) )
            } else if(dayIndex == 3) {
                this.daysAvailable = this.availability.filter( i => 'Wednesday'.includes( i.day ) )
            } else if(dayIndex == 4) {
                this.daysAvailable = this.availability.filter( i => 'Thursday'.includes( i.day ) )
            } else if(dayIndex == 5) {
                this.daysAvailable = this.availability.filter( i => 'Friday'.includes( i.day ) )
            } else if(dayIndex == 6) {
                this.daysAvailable = this.availability.filter( i => 'Saturday'.includes( i.day ) )
            }
        },
    },

    computed: {
        allCase() {
            return this.$store.state.clientCases.data
        },
    }

  }
</script>

<style scoped>

.title_bg {
    background-color: #023E7D;
    color: #fff;
}

.stepper_btn {
    color: #fff;
}

.some-style >>> .v-input__slot::before {
    border-style: none !important;
}

.check_container {
    border: solid 1px #023E7D;
    border-radius: 3px;
    background-color: #F6FAFF;
    padding: 5px 10px;
    height: 52px;
}

.check_text {
    color: #023E7D;
}

.form_link {
    color: #023E7D;
    font-weight: bold;
    text-decoration: none;
}

</style>