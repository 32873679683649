<template>
    <v-dialog v-model="dialog" max-width="600">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
            <v-skeleton-loader
              v-if="attorneyLoader"
              class="mx-auto my-2"
              max-width="80"
              height="80"
              type="card"
            ></v-skeleton-loader>
            <div v-else class="my-2">
                <v-avatar rounded size="80" color="#DCE8F8" class="me-4">
                  <img
                    :src="attorneyDetails?.attorney_profile?.profile_image_url"
                    alt="profile image"
                  >
                </v-avatar>
                <div class="pt-1">
                  <h3 class="attorney_name text-capitalize">{{attorneyDetails?.attorney?.first_name}} {{attorneyDetails?.attorney?.last_name}} <v-icon v-show="attorneyDetails?.attorney_profile?.is_profile_verified" color="#e9c46a" small>mdi-check-decagram</v-icon></h3>
                  <div class="d-flex justify-center">
                    <span class="d-flex align-center">Ratings <v-chip class="ms-1" small>{{ attorneyDetails?.attorney_profile?.avg_rating || 0 }}<v-icon small>mdi-star</v-icon></v-chip></span>
                  </div>
                </div>
            </div>
          <div v-if="!hadRate">
            <span class="rate_subtitle">Rate your experience with this service provided to you by this attorney?</span>
            <v-rating
              v-model="rating"
              background-color="grey lighten-1"
              color="yellow darken-2"
              class="rating_star"
              size="50"
              hover
              half-increments
            ></v-rating>
            <v-text-field
            v-model="reason"
            label="What's your reason for this rating?"
            :hide-details="true"
            :rules="[rules.required, wordsRules]"
            filled
            dense
            counter="600"
            :counter-value="v => v.trim().split(' ').length"
            class="some-style"
            ></v-text-field>
          </div>
          <span v-else class="rate_subtitle">Thanks for sending your ratings and review.</span>
        </v-card-text>
        <v-card-actions v-show="!hadRate" class="pb-4 px-6">
          <v-btn class="px-6 me-2" color="#023E7D" outlined @click="closeDialog">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="px-6" color="#023E7D" elevation="0" :loading="loading" dark @click="submitRating">Submit</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
</template>
  
<script>
  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      endCaseId: {
        type: String,
        required: true,
      },
      endAttorneyId: {
        type: String,
        required: true,
      },
      hadRate: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {
        dialog: this.value,
        rating: 0,
        reason: '',
        loading: false,
        attorneyLoader: false,
        attorneyDetails: {},
        wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],
        rules: {
          required: value => !!value || 'Required.',
        },
        valid: true,
      };
    },
    watch: {
      value(val) {
        this.dialog = val;
      },
      dialog(val) {
        this.$emit('input', val);
        if(val == false) return
        this.getAttorney()
        this.rating = 0
        this.reason = ''
      }
    },
    methods: {
      closeDialog() {
        this.dialog = false;
      },
      submitRating() {
        let formstatus = this.$refs.form.validate()
        if(formstatus != true) return
        this.loading = true
        this.$store.dispatch('submitRating', {
        rating: this.rating,
        review: this.reason,
        caseId: this.endCaseId,
        attorneyId: this.endAttorneyId
        })
        .then(() => {
        this.loading = false
        this.snackbar = true
        this.message = 'Attorney rated successfully'
        this.bgcolor = 'success'
        this.dialog = false
        this.$store.dispatch('getClientCases');
        this.$emit('rated', this.rating);
        this.dialog = false;
        this.rating = 0
        this.reason = ''
        })
        .catch((error) => {
        this.loading = false
        this.snackbar = true
        this.message = error.response.data.error
        this.bgcolor = 'error'
        })
      },
      getAttorney() {
        this.attorneyLoader = true
        this.$store.dispatch('getAttorneyById', this.endAttorneyId)
        .then((response) => {
            this.attorneyDetails = response.data
            this.attorneyLoader = false
        })
        .catch(() => {
          this.attorneyLoader = false
        })
      }
    }
  };
</script>
  
<style scoped>
.some-style >>> .v-input__slot::before {
    border-style: none !important;
}
.attorney_name {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.rate_title {
    font-size: 18px;
    margin-bottom: 10px;
}
.rate_subtitle {
    font-size: 14px;
}
.rating_star {
    margin-top: 30px;
    margin-bottom: 30px;
}
</style>
  