export const excludedEndpoints = [
    '/auth/login',
    '/auth/register',
    '/auth/get-otp',
    '/auth/waitlist',
    '/auth/validate-otp?token=',
    '/stripe/create-subscription',
    '/stripe/create-card-charge?token=',
    '/auth/verify-email?token=',
    '/auth/reset-password?token=',
    '/auth/zip-codes/primary-city?key_word=',
    '/auth/states',
    '/auth/practice-areas',
    '/stripe/get-plans',
    '/auth/zip-codes?primary_city=',
];