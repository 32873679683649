<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic text-capitalize">Hello! {{userData?.user?.first_name}}</h3>
                    <p class="subtopic">Let’s find you clients</p>
                    <v-row class="my-4">
                        <v-col cols="12" lg="4">
                            <router-link class="card_link" to="/attorney/appointments">
                            <v-card
                            color="#FFF7CC"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{attorneyBookeds?.length}}</h2>
                                    <span class="card_figure-text text-caption">Appointment</span>
                                </v-card-text>
                            </v-card>
                        </router-link>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <router-link class="card_link" to="/attorney/cases">
                            <v-card
                            color="#B7EBF1"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{attorneyCases.matchedCount}}</h2>
                                    <span class="card_figure-text text-caption">Matched Cases</span>
                                </v-card-text>
                            </v-card>
                        </router-link>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <router-link class="card_link" to="/attorney/cases">
                            <v-card
                            color="#FAEDD9"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{attorneyCases.retainedCount}}</h2>
                                    <span class="card_figure-text text-caption">Retained  Cases</span>
                                </v-card-text>
                            </v-card>
                        </router-link>
                        </v-col>
                    </v-row>
                    <h4 class="topic mb-3">Cases</h4>
                    <div class="my-3">
                        <v-text-field
                            rounded
                            filled
                            dense
                            label="Search case name or location"
                            append-icon="mdi-magnify"
                            :hide-details="true"
                            class="some-style"
                        ></v-text-field>
                    </div>
                    <div class="d-flex justify-space-between">
                        <div>
                            <v-chip
                                class="pe-0 mr-2"
                                color="#DCE8F8"
                            >
                                {{ attorneyType[selectedType] }}
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    class="ms-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon small>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item-group
                                        v-model="selectedType"
                                        color="primary"
                                    >
                                    <v-list-item
                                    v-for="(item, i) in attorneyType"
                                    :key="i"
                                    >
                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                </v-menu>
                            </v-chip>
                            <v-chip
                                class="pe-0 mr-2"
                                color="#DCE8F8"
                            >
                                {{ practiceAreas[selectedArea].name }}
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    class="ms-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon small>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                    <v-responsive
                                    class="overflow-y-auto"
                                    max-height="300"
                                    >

                                        <v-responsive>
                                            <v-list>
                                                <v-list-item-group
                                                    v-model="selectedArea"
                                                    color="primary"
                                                >
                                                <v-list-item
                                                v-for="(item, i) in practiceAreas"
                                                :key="i"
                                                >
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-responsive>
                                    </v-responsive>
                                </v-menu>
                            </v-chip>
                        </div>
                        <div>
                            <v-chip
                                class="pe-0 mr-2"
                                color="#F5F5F5"
                            >
                                <span class="slot_rating-text">5</span>
                                <v-icon size="16">
                                    mdi-star-outline
                                </v-icon>
                                <v-btn
                                icon
                                small
                                >
                                <v-icon small>mdi-chevron-down</v-icon>
                                </v-btn>
                                <v-btn
                                icon
                                small
                                >
                                <v-icon small>mdi-chevron-up</v-icon>
                                </v-btn>
                            </v-chip>
                            <v-chip
                                class="px-0 mr-2"
                                color="#F5F5F5"
                            >
                                <v-btn
                                icon
                                color="#023E7D"
                                small
                                >
                                <v-icon small>mdi-check-decagram</v-icon>
                                </v-btn>
                                <v-btn
                                icon
                                color="#023E7D"
                                small
                                >
                                <v-icon small>mdi-decagram</v-icon>
                                </v-btn>
                            </v-chip>
                        </div>
                    </div>
                    <v-row class="my-1">
                        <v-col cols="12">
                            <v-card flat>
                                <v-responsive
                                class="overflow-x-auto"
                                >
                                    <v-responsive>
                                    <div
                                    v-if="attorneysLoader === false"
                                    class="d-flex justify-start"
                                    >
                                    <v-card
                                        color="#023E7D"
                                        v-for="(item, i) in attorneys"
                                        :key="i"
                                        class="me-4"
                                    >
                                        <v-card-text class="d-flex justify-start">
                                            <v-avatar size="40">
                                                <img
                                                    :src="imgPlaceholder"
                                                    alt="profile image"
                                                >
                                            </v-avatar>
                                            <div class="ms-2">
                                                <h3 class="card_title">{{item?.user_id?.last_name}} {{item?.user_id?.first_name}} <v-icon v-show="item.is_profile_verified" color="#e9c46a" small>mdi-check-decagram</v-icon></h3>
                                                <span class="card_text">{{ item?.attorney_type[0] }}</span>
                                                <span class="ms-1 card_text" v-show="item?.attorney_type?.length > 1">& More</span>
                                            </div>
                                        </v-card-text>
                                        <div class="px-4 pb-2 d-flex justify-space-between align-center">
                                            <v-chip
                                            v-show="!item.has_active_boost"
                                            small
                                            outlined
                                            color="#fff"
                                            class="promo_tag"
                                            >
                                            Boosted
                                            </v-chip>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            icon
                                            small
                                            outlined
                                            color="#fff"
                                            @click="attProfile(item)"
                                            >
                                            <v-icon small>mdi-arrow-top-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-card>
                                    </div>
                                    <div v-else>
                                        <v-skeleton-loader
                                        type="list-item-avatar-two-line"
                                        ></v-skeleton-loader>
                                    </div>
                                    </v-responsive>
                                </v-responsive>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-responsive
                            class="overflow-y-auto"
                            max-height="400"
                            >
                                <v-responsive>
                                <div
                                v-if="attorneysLoader === false"
                                >
                                <v-row>
                                    <v-col 
                                    lg="4"
                                    v-for="(item, i) in attorneys"
                                    :key="i"
                                    >
                                        <v-card
                                            outlined
                                        >
                                            <v-card-title>
                                                <v-avatar size="40">
                                                    <img
                                                        :src="imgPlaceholder"
                                                        alt="profile image"
                                                    >
                                                </v-avatar>
                                                <v-spacer></v-spacer>
                                                <v-chip
                                                v-show="item.has_active_boost"
                                                small
                                                outlined
                                                class="promo_tag"
                                                >
                                                Verified
                                                </v-chip>
                                            </v-card-title>
                                            <v-card-text>
                                                <h3>{{item.user_id.last_name}} {{item.user_id.first_name}} <v-icon v-show="item.is_profile_verified" color="#023E7D" small>mdi-check-decagram</v-icon></h3>
                                                <span 
                                                v-for="(type, i) in item.attorney_type"
                                                :key="i"
                                                class="profile_subtitle text-capitalize"
                                                >
                                                {{ type }}, 
                                                </span>
                                                <div class="mt-1 d-flex justify-start align-center">
                                                    <div>
                                                        <v-icon small class="me-2 profile_icon" color="#7E889A">
                                                            mdi-briefcase-check-outline
                                                        </v-icon>
                                                        <span class="me-3 rating_text">0</span>
                                                    </div>
                                                    <div>
                                                        <v-icon small class="me-2 profile_icon" color="#7E889A">
                                                            mdi-briefcase-clock-outline
                                                        </v-icon>
                                                        <span class="me-3 rating_text">0</span>
                                                    </div>
                                                    <div>
                                                        <v-icon small class="me-2 profile_icon" color="#7E889A">
                                                            mdi-briefcase-remove-outline
                                                        </v-icon>
                                                        <span class="me-3 rating_text">0</span>
                                                    </div>
                                                    <div class="me-2 line"></div>
                                                    <div>
                                                        <span class="ms-1 me-1 rating_text">0</span>
                                                        <v-icon small class="profile_icon" color="#7E889A">
                                                            mdi-star-outline
                                                        </v-icon>
                                                        
                                                    </div>
                                                </div>
                                            </v-card-text>
                                            <v-card-actions>
                                                <span class="ms-2 action_text">
                                                    {{item.user_id.state}}
                                                </span>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                icon
                                                outlined
                                                @click="attProfile(item)"
                                                >
                                                <v-icon>mdi-arrow-top-right</v-icon>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                </div>
                                <div v-else>
                                    <v-skeleton-loader
                                    type="list-item-avatar-two-line"
                                    ></v-skeleton-loader>
                                </div>
                                </v-responsive>
                            </v-responsive>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" lg="4" class="right_sidebar">
                    <ApptCard />
                    <CaseCard />
                    <SetupCard />
                </v-col>
                
            </v-row>
        </v-container>

        <!-- open case -->
        <v-dialog
        v-model="newCaseDialog"
        max-width="420"
        >
        <v-card class="py-4" color="#023E7D">
            <v-card-title class="mb-4">
                <v-avatar size="40" color="#DCE8F8">
                    <v-icon color="#023E7D" dark>
                        mdi-briefcase-outline
                    </v-icon>
                </v-avatar>
            </v-card-title>
            <v-card-text>
                <h3 class="dialog_title">New Prospective Cases.</h3>
                <p class="dialog_subtitle">Sed egestas, dui a accumsan sagittis, sapien mauris placerat  eu bi purus eros felis viverra nisi nec tincidunt ull amcorper. Donec ac blandit felis.</p>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="white"
                text
                @click="openCaseDialog = false"
            >
                Later
            </v-btn>
            <v-btn
                color="white"
                dark
                depressed
                route to="/client/cases"
                class="dialog_btn"
            >
                View Now
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- invoice preview -->
        <v-dialog
        v-model="invoicePreview"
        max-width="700"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Invoice Preview
                </v-card-title>
            </div>
            <v-card-text v-if="invoiceLoaded === false" class="mt-8">
                <v-row>
                    <v-col lg="3">
                        <h5>Client Name</h5>
                        <div class="case_text">
                            {{ invoiceDetails.client_name }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Invoice No</h5>
                        <div class="case_text">
                            {{ invoiceDetails.invoice_no }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Due Date</h5>
                        <div class="case_text">
                            {{ invoiceDetails.due_date | formatDate }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Case Type</h5>
                        <div class="case_text">
                            {{ invoiceDetails.service_rendered }}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Case ID</h5>
                        <div class="case_text">
                            #{{ invoiceDetails.case_id }}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Note</h5>
                        <div class="case_text">
                            {{ invoiceDetails.comment }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Description
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                                </tr>
                            </thead>
                            <tbody v-if="invoiceDetails?.services?.length > 0">
                                <tr
                                v-for="(item, i) in invoiceDetails.services"
                                :key="i"
                                >
                                <td>{{ item.description }}</td>
                                <td>${{ item.cost }}</td>
                                </tr>
                            </tbody>
                            <div
                            v-else 
                            class="mt-4"
                            >
                            <v-card
                            flat 
                            width="480"

                            class="d-flex jusity-start align-center"
                            >
                                <v-icon
                                size="50"
                                color="#D4D4D4"
                                class="me-2"
                                >
                                mdi-view-list-outline
                                </v-icon>
                                <span class="placeholder_text">There's currently no service item to display, click the add service button below to add</span>
                            </v-card>
                            </div>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <span class="text_caption me-2">Total</span>
                        <span class="invoice_total display-1">${{ invoiceDetails.amount }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else>
                <v-row>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="6">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="6">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader
                        type="image, table-tfoot"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- <v-btn
          class="chat_icon mx-2"
          absolute
          fab
          dark
          large
          right
          bottom
          color="purple"
        >
          <v-icon dark>
            mdi-android
          </v-icon>
        </v-btn> -->
    </div>
</template>

<script>
import ApptCard from '../../components/attorney/AppointmentCardAttorney.vue'
import CaseCard from '../../components/attorney/CaseCardAttorney.vue'
import SetupCard from '../../components/attorney/SetupCardAttorney.vue'
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'

  export default {

    components: {
        ApptCard, CaseCard, Sidebar, Navbar, SetupCard
    },

    created: function () {
    //   setTimeout(() => {
    //       this.newCaseDialog = true
    //   }, 5000);
        // this.$store.dispatch('getAttorneyCases')
        // this.$store.dispatch('getAllInvoices')
        this.$store.dispatch('getPractice')
    },

    data () {
      return {
        newCaseDialog: false,
        tab: 0,
        invoicePreview: false,
        invoiceLoaded: false,
        invoiceDetails: {},
        attProDialog: false,
        openCaseDialog: false,
        attorneysLoader: false,
        attorneys: [],
        attorneyProfile: {},
        imgPlaceholder: 'https://res.cloudinary.com/dzsshr0iz/image/upload/v1652961985/Ilerah/avatar-ph_pce6l3.jpg',
        attorneyType: ['All Types', 'Criminal Defense', 'Civil Plaintiff', 'Civil Defense', 'Transaction'],
        selectedType: 0,
        selectedArea: 0,
      }
    },

    methods: {
        selectedTxn(item) {
            console.log(item)
            this.invoicePreview = true
            this.invoiceLoaded= true
            this.$store.dispatch('getInvoiceById', item.transaction.invoice_id)
            .then((response) => {
                console.log(response)
                this.invoiceLoaded= false
                this.invoiceDetails = response.data.data.invoice
            })
            .catch((error) => {
                this.invoiceLoaded= false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
        attProfile(item) {
            this.attorneyProfile  = item;
            this.attProDialog = true
        },
    },

    computed: {
        userData() {
            return this.$store.state.userData;
        },
        attorneyCases() {
            return this.$store.state.attorneyCases;
        },
        // attorneyCalendar() {
        //     return this.$store.state.attorneyCalendar;
        // },
        attorneyBookeds() {
            let calendarCheck = 'booked';
            let calendar = this.$store?.state?.attorneyCalendar?.calendars?.data;
            return calendar?.filter((i) => i.is_consultant === false && i.status === calendarCheck);
        },
        practiceAreas() {
            let newObject = {name: "All Areas", description: "All practice areas"};
            return [newObject, ...this.$store.state.practiceAreas];
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }
    .subtopic {
        color: #7E889A;
    }
    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }
    .card_figure-text {
        color: #023E7D;
    }
    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .attorney_card-box {
        height: 34px;
    }
    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }
    .list_text {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
    }
    .dialog_title {
        font-size: 25px;
        color: #FFF;
        margin-bottom: 20px;
    }
    .dialog_subtitle {
        color: #FFF;
    }
    .dialog_btn {
        color: #023E7D;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
    .placeholder_text {
        color: #A7A7A7;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .card_link {
    text-decoration: none;
}
</style>