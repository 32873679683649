<template>
    <div>
      <v-container>
        <v-row>
          <v-col class="text-center" cols="12">
              <div class="box d-flex justify-center align-center">
                  <div>
                      <v-avatar color="#023E7D" class="mb-2">
                          <v-icon dark>
                              mdi-shield-account-outline
                          </v-icon>
                      </v-avatar>
                      <h1 class="auth_title">Verification Code</h1>
                      <p class="auth_subtitle font-weight-medium">{{ resetDetails.message }}</p>
                      <div class="d-flex justify-center">
                      <v-card width="300" flat>
                          <v-otp-input
                              v-model="otp"
                              :disabled="loading"
                              @finish="onFinish"
                          ></v-otp-input>
                          <v-overlay absolute :value="loading">
                              <v-progress-circular
                              indeterminate
                              color="primary"
                              ></v-progress-circular>
                          </v-overlay>
                      </v-card>
                      </div>
                      <v-btn
                          elevation="0"
                          class="mt-12"
                          small
                          text
                          :loading="resentLoader"
                          @click="resendOtp"
                      >
                          Resend verification code
                      </v-btn>
                  </div>
              </div>
          </v-col> 
        </v-row>
      </v-container>
  
      <!-- snackbar -->
      <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        right
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
      </v-snackbar>
    </div>
</template>
  
  <script>
  
    export default {
  
      components: {
  
      },
      
      data () {
        return {
          loading: false,
          resentLoader: false,
          otp: '',
  
          snackbar: false,
          message: '',
          bgColor: '',
        }
      },
  
      methods: {
        onFinish () {
          this.loading = true
          this.$store.dispatch('verifyResetPwd', {
            code: this.otp,
            resetTok: this.resetDetails.data.reset_password
          })
          .then(() => {
            this.loading = false
            this.$router.push({
              name:'NewPassword',
            })
          })
          .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
          })
        },

        resendOtp() {
            this.resentLoader = true
            this.$store.dispatch('forgotPassword', {
                email: this.resetDetails.email,
                action: 'reset-password'
            })
            .then(() => {
              this.resentLoader = false
              this.snackbar = true
              this.message = `Code resent to ${this.resetDetails.email}`
              this.bgColor = 'success'
            })
            .catch((error) => {
            this.resentLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },
  
      },

      computed: {
        resetDetails() {
            return this.$store.state.resetDetails;
        },
      }
  
    }
  </script>
  
  <style scoped>
  
    .brand_logo {
      width: 120px;
    }
  
    .box {
      height: 100vh;
    }
  
    .position-relative {
      position: relative;
    }
    
  </style>
  