<template>
    <div>
        <v-app-bar
        color="white"
        light
        flat
        >
        <div>
            <v-img class="logo_img"
            src="../assets/legallync-logo-dark.png"
            ></v-img>
        </div>
        <v-spacer></v-spacer>
        </v-app-bar>
    <div class="main_body pa-4 d-flex align-center">
        <div class="text-center box">
            <v-avatar color="#023E7D" class="mb-2">
                <v-icon dark>
                    mdi-form-textbox-password
                </v-icon>
            </v-avatar>
            <h4 class="heading">Reset Password?</h4>
            <span class="subheading">Your new password must be different from previously used password.</span>
            <v-form 
            class="mt-6 mb-4"
            >
                <v-text-field
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  label="Password"
                  filled
                  dense
                  class="some-style"
                  @click:append="show = !show"
                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, matchingPasswords]"
                  :type="show1 ? 'text' : 'password'"
                  label="Confirm Password"
                  filled
                  dense
                  class="some-style"
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-btn
                block
                large
                color="#023E7D"
                elevation="0"
                class="white--text"
                :loading="loading"
                :disabled="loading"
                @click="onFinish"
                >
                Send
                </v-btn>
            </v-form>
            <router-link class="body_link" to="/">Back to login</router-link>
        </div>

        <!-- snack bar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgcolor"
        dark
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="snackbar = false"
            >
            <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
        </template>
        </v-snackbar>
    </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        loading: false,
        show: false,
        show1: false,
        valid: true,
        password: '',
        confirmPassword: '',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 6 || 'Minimum 8 characters password',
          emailRules: v => /.+@.+\..+/.test(v) || 'Email must be valid',
          // emailMatch: () => (`The email and password you entered don't match`),
        },
        snackbar: false,
        message: '',
        bgcolor: '',
    }),

    methods: {
        onFinish () {
            console.log(this.password)
            console.log(this.resetDetails.data.reset_password)
            // let formstatus = this.$refs.form.validate()
            // if(formstatus != true) return
            this.loading = true
            this.$store.dispatch('updatePassword', {
                password: this.password,
                updateTok: this.resetDetails.data.reset_password
            })
            .then(() => {
                this.loading = false
                this.message = 'Password updated successfully'
                this.snackbar = true
                this.bgcolor = 'success'
                this.$router.push('/');
            })
            .catch((error) => {
                this.loading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        matchingPasswords: function () {
            if (this.password === this.confirmPassword) {
                return true;
            } else {
                return "Passwords does not match.";
            }
        },
    },

    computed: {
        resetDetails() {
            return this.$store.state.resetDetails;
        },
    }
};
</script>

<style scoped>
.main_body {
    height: 100vh;
}
.logo_img {
    width: 160px;
}
.box {
    width: 400px;
    margin: auto;
}
.main_img {
    width: 50px;
    margin-bottom: 20px;
}
.heading {
    font-weight: 800;
    font-size: 25px;
}
.subheading {
    font-size: 14px;
}
.body_link {
    text-decoration: none;
    font-size: 14px;
    color: #023E7D;
}
.some-style >>> .v-input__slot::before {
    border-style: none !important;
  }

</style>