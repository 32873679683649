import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import moment from 'moment';
import WebSocketPlugin from './WebSocketPlugin';
import EventBus  from './EventBus';
import OfflinePage from './views/OfflinePage.vue'
import { excludedEndpoints } from './excludedEnpoints'


axios.defaults.baseURL= `${process.env.VUE_APP_BASE_URL}`;

Vue.config.productionTip = false

Vue.use(WebSocketPlugin, {
  websocketUrl: "wss://dev.legallync.com",
});

let isRefreshAttempted = false; // Flag to track whether refresh has been attempted

axios.interceptors.response.use(
  (response) => response,
  async (error) => {

    const requestUrl = error.config.url;

    // Check if the URL is in the list of excluded endpoints
    const isExcludedEndpoint = excludedEndpoints.some(endpoint => requestUrl.endsWith(endpoint));

    // If the URL matches an excluded endpoint, skip the interceptor logic
    if (isExcludedEndpoint) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    console.log('got 401 error')

    // Check if the error is a 401 Unauthorized and if it's not a retry
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      console.log('Refresh Attempted false')

      // Check if refresh has been attempted
      if (!isRefreshAttempted) {
        isRefreshAttempted = true; // Set the flag to indicate refresh attempt

        try {
          // Attempt to refresh the token using your API endpoint
          // eslint-disable-next-line no-unused-vars
          console.log('Attempted new token')
          const refreshToken = localStorage.getItem('refresh_token');
          const response = await axios.post(`/auth/get-refresh-token?refresh_token=${refreshToken}`, {
            
          });
          console.log('newAccessToken')
          console.log(response)

          const newAccessToken = response.data.token;

          // Update the stored access token
          localStorage.setItem('access_token', newAccessToken);
          console.log('new token save Attempted original request')

          // Retry the original request with the new access token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          // Handle refresh token failure (e.g., logout user)
          // console.error('Refresh token failed', refreshError);

           // Show a toast using Vuetify's Snackbar component
           EventBus.dispatch('show-snackbar', {
            color: 'error',
            message: 'Session expired, please log in again.'
          });

          // Logout user using the Vuex store
          // store.dispatch('logout');
          console.log('refresh token failed log out')

          // Redirect to the login page or perform additional actions
          // localStorage.clear()

          // router.push('/');

          // Throw an error to reject the original request
          // return 
          return Promise.reject(error);
        }
      }
    } else {
        // General error handling for all other errors
        const errorMessage = error?.response?.data?.error || error.message || "An unexpected error occurred";
        EventBus.dispatch("show-snackbar", {
          color: "error",
          message: errorMessage,
        });
    }

    // If it's not a 401 error or it's a retried request, simply return the error
    // return
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        path: '/loading',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
     return moment.utc(value).format('MMMM D, YYYY');
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('hh:mm A')
  }
});

Vue.filter('resetTime', function(value) {
  if (value) {
    return moment(value, ["HH:mm"]).format("h:mm A")
  }
});

Vue.filter('formatState', function(value) {
  if (value) {
    return value.replace(/\b[A-Z]{2}\b/g, '')
  }
});

Vue.filter('amountFormat', num => {
  if (!num) {
    return '0.00';
  }
  const number = (num / 1).toFixed(2).replace(',', '.');
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

Vue.filter('formatNumber', function(value) {
  const number = parseFloat(value);
  if (!isNaN(number)) {
    return number.toFixed(1);
  }
  return value;
});

Vue.filter('commaSeparated', function(value) {
  if (!Array.isArray(value)) {
    return value;
  }

  return value.map((item, index) => {
    if (index === value.length - 1) {
      return item;
    }
    return `${item}, `;
  }).join('');
});

new Vue({
  router,
  store,
  vuetify,
  data() {
    return {
      onlineStatus: navigator.onLine
    };
  },
  computed: {
    isOnline() {
      return this.onlineStatus;
    }
  },
  created() {
    window.addEventListener('online',  () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));
  },
  methods: {
    updateOnlineStatus(status) {
      this.onlineStatus = status;
    }
  },
  render(h) {
    if (this.isOnline) {
      return h(App); // Render the main app if online
    } else {
      return h(OfflinePage); // Render the offline page if offline
    }
  }
}).$mount('#app')
