<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-snackbar
      v-model="snackbar"
      :top="isTop"
      :bottom="isBottom"
      :right="isRight"
      :left="isLeft"
      :color="snackbarColor"
      text
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbarColor"
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import EventBus from "./EventBus";
  export default {
    name: "App",
    data: () => ({
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      snackbarPosition: "bottom-right",
    }),
    computed: {
      isTop() {
        return this.snackbarPosition.includes("top");
      },
      isBottom() {
        return this.snackbarPosition.includes("bottom");
      },
      isRight() {
        return this.snackbarPosition.includes("right");
      },
      isLeft() {
        return this.snackbarPosition.includes("left");
      },
    },
    methods: {
      showSnackbar(data) {
        this.snackbar = true;
        this.snackbarColor = data.color;
        this.snackbarMessage = data.message;
        if (data.position) this.snackbarPosition = data.position;
      },

      handleShowSnackbar(data) {
        this.showSnackbar(data);
      },
    },
    mounted() {
      EventBus.on("show-snackbar", this.handleShowSnackbar);
    },
    beforeDestroy() {
      EventBus.remove("show-snackbar", this.handleShowSnackbar);
    },
  };
</script>

<style>

  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Arvo:wght@400;700&display=swap');

  #app {
    font-family: 'Arvo', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Raleway', sans-serif;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .v-main {
      padding: 0 !important;
    }
  }

</style>
