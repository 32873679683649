<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic">Appointment</h3>
                    
                    <v-row class="my-3">
                        <v-col cols="12">
                            <Calendar />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="mt-7 right_sidebar">
                    <div class="mb-4 text-end">
                        <SetCalendar />
                    </div>
                    <v-card
                    color="#023E7D"
                    flat
                    class="mb-2"
                    >
                        <v-card-text class="d-flex justify-start">
                            <v-avatar 
                            color="#FFFDF1"
                            rounded
                            class="me-4"
                            >
                            <v-icon color="#023E7D">
                                mdi-calendar-check-outline
                            </v-icon>
                            </v-avatar>
                            <div>
                                <h4 class="card_lheading">{{attorneyBookeds?.length || 0}}</h4>
                                <span class="card_lsubheading">Appointments</span>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card
                    color="#023E7D"
                    flat
                    class="mb-2"
                    >
                        <v-card-text class="d-flex justify-start">
                            <v-avatar 
                            color="#FFFDF1"
                            rounded
                            class="me-4"
                            >
                            <v-icon color="#023E7D">
                                mdi-calendar-question-outline
                            </v-icon>
                            </v-avatar>
                            <div>
                                <h4 class="card_lheading">{{attorneyConsultations?.length || 0}}</h4>
                                <span class="card_lsubheading">consultations</span>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card
                    color="#023E7D"
                    flat
                    class="mb-2"
                    >
                        <v-card-text class="d-flex justify-start">
                            <v-avatar 
                            color="#FFFDF1"
                            rounded
                            class="me-4"
                            >
                            <v-icon color="#023E7D">
                                mdi-calendar-remove-outline
                            </v-icon>
                            </v-avatar>
                            <div>
                                <h4 class="card_lheading">{{attorneyBlocked?.length || 0}}</h4>
                                <span class="card_lsubheading">Blocked Days</span>
                            </div>
                        </v-card-text>
                    </v-card>
                    <TranCard />
                </v-col>
                
            </v-row>
        </v-container>
    </div>
</template>

<script>
import TranCard from '../../components/attorney/TransactionCardAttorney.vue'
import SetCalendar from '../../components/attorney/SetCalendar.vue'
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'
import Calendar from '../../components/attorney/CalendarEvent.vue'

  export default {

    components: {
        TranCard, 
        SetCalendar, Sidebar, Navbar, Calendar
    },

    created: function () {
    },

    data () {
      return {
          
      }
    },

    methods: {
        initialMeeting(item) {
            let chat_object = item.chat_object[0] 
            this.$router.push({
                name: 'AttorneyMeet',
                params: {
                displayName: chat_object.display_name,
                roomName: chat_object.room_name,
                email: chat_object.email,
                password: chat_object.password,
                }
            });
        }
    },

    computed: {
        attorneyBookeds() {
            let calendarCheck = 'booked';
            let calendar = this.$store?.state?.attorneyCalendar?.calendars?.data;
            return calendar?.filter((i) => i.is_consultant === false && i.status === calendarCheck);
        },
        attorneyConsultations() {
            let calendarCheck = 'booked';
            let calendar = this.$store?.state?.attorneyCalendar?.calendars?.data;
            return calendar?.filter((i) => i.is_consultant === true && i.status === calendarCheck);
        },
        attorneyBlocked() {
            let calendarCheck = 'blocked';
            let calendar = this.$store?.state?.attorneyCalendar?.calendars?.data;
            return calendar?.filter((i) => i.is_actively_blocked === true && i.status === calendarCheck);
        },

        attorneyCalendar() {
            return this.$store.state.attorneyCalendar.calendars.data;
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }

    .subtopic {
        color: #7E889A;
    }

    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }

    .card_figure-text {
        color: #023E7D;
    }
    .client_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .subtitle {
        color: #023047;
    }
    .figure {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
    .card_lheading {
        font-size: 25px;
        color: #FFF;
    }
    .card_lsubheading {
        font-size: 12px;
        color: #FFF;
    }
</style>