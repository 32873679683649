<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic">Invoice</h3>
                    <v-row class="my-4">
                        <v-col cols="12" lg="6">
                            <v-card
                            color="#FFF7CC"
                            flat
                            @click="paidCard"
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{ invoices?.paidCount }}</h2>
                                    <span class="card_figure-text text-caption">Paid Invoices</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-card
                            color="#FAEDD9"
                            flat
                            @click="pendingCard"
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{ invoices?.pendingCount }}</h2>
                                    <span class="card_figure-text text-caption">Pending Invoices</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <h4 class="topic mb-3">Recent Invoices</h4>
                    <div class="d-flex justify-space-between align-center">
                            <v-text-field
                            v-model="search"
                            rounded
                            filled
                            dense
                            label="Search invoice description"
                            append-icon="mdi-magnify"
                            :hide-details="true"
                            class="some-style"
                        ></v-text-field>
                        <div class="ms-4">
                            <v-chip
                                class="pe-0 mr-2 text-capitalize"
                                color="#DCE8F8"
                            >
                                {{ invoiceStatus[selectedType] }}
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    class="ms-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon small>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item-group
                                        v-model="selectedType"
                                        color="primary"
                                    >
                                    <v-list-item
                                    v-for="(item, i) in invoiceStatus"
                                    :key="i"
                                    >
                                    <v-list-item-title class="text-capitalize">{{ item }}</v-list-item-title>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                </v-menu>
                            </v-chip>
                            <v-btn
                            icon
                            small
                            @click="sort = !sort"
                            >
                            <v-icon small>{{sort  ? 'mdi-order-bool-descending' : 'mdi-order-bool-ascending'}}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-row class="my-3">
                        <v-col cols="12">
                            <v-card 
                            height="400"
                            flat
                            >
                            <div v-if="invoiceLoader">
                                <v-skeleton-loader
                                type="list-item-avatar-two-line"
                                ></v-skeleton-loader>
                            </div>
                            <v-responsive
                            v-else-if="invoices?.data?.length > 0 && !invoiceLoader"
                            class="overflow-y-auto"
                            max-height="400"
                            >
                                <v-responsive>
                                    <div
                                    v-for="(item, i) in invoices?.data"
                                    :key="i"
                                    >
                                        <a 
                                        class="case_link flex-grow-1 flex-shrink-0" 
                                        style="min-width: 100px; max-width: 100%;"
                                        @click="selectedTxn(item)"
                                        >
                                            
                                            <div class="mt-2 attorney_card d-flex justify-space-between">
                                                <div class="attorney_card-box d-flex align-center">
                                                    <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                        <v-icon color="#C3D6EF" dark>
                                                        mdi-receipt-text
                                                        </v-icon>
                                                    </v-avatar>
                                                    <span>{{ item.case_name }} | {{ item.client_name }}</span>
                                                </div>
                                                <div class="attorney_card-box d-flex align-center text-capitalize">
                                                <span class="list_text me-12">{{ item.status }} - {{ item.createdAt | formatDate }}</span>
                                                <span class="list_text me-4">${{ item.amount | amountFormat }}</span>
                                                <v-icon class="mx-1" color="#023E7D">mdi-chevron-right</v-icon>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </v-responsive>
                            </v-responsive>
                            <div v-else-if="invoices?.data?.length === 0 && !invoiceLoader" class="text-center d-flex justify-center">
                                <v-card flat width="400" height="390" class="text-center d-flex align-center">
                                    <div>
                                        <v-icon
                                        size="100"
                                        color="#D4D4D4"
                                        >
                                        mdi-package-variant
                                        </v-icon>
                                        <p class="placeholder_text">Nothing to see here! You don't have any case, create a case and it will show up on this list.</p>
                                    </div>
                                </v-card>
                            </div>
                            </v-card>
                            <div class="py-2 d-flex justify-end">
                                <v-pagination
                                v-model="page"
                                :length="invoices?.totalPages"
                                circle
                                color="#023E7D"
                                ></v-pagination>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="right_sidebar">
                    <ApptCard />
                </v-col>
                
            </v-row>
        </v-container>

        <!-- invoice preview -->
        <v-dialog
        v-model="invoicePreview"
        max-width="700"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Invoice Preview
                </v-card-title>
            </div>
            <v-card-text v-if="invoiceLoaded === false" class="mt-8">
                <v-row>
                    <v-col cols="12">
                        <v-stepper alt-labels class="elevation-0">
                            <v-stepper-header class="elevation-0">
                            <v-stepper-step
                                step="1"
                                :complete="true"
                            >
                                Invoice Sent
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                step="2"
                                :rules="[() => invoiceDetails.status == 'failed' ? false : true ]"
                                :complete="invoiceDetails.status == 'failed' ? false : true"
                            >
                                Invoice Pending
                                <small v-show="invoiceDetails.status == 'failed'">Client payment failed</small>
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                step="3"
                                :complete="invoiceDetails.status == 'paid' ? true : false"
                            >
                                Invoice Paid
                                
                            </v-stepper-step>
                            </v-stepper-header>
                        </v-stepper>
                    </v-col>
                    <v-col lg="3">
                        <h5>Client Name</h5>
                        <div class="case_text">
                            {{ invoiceDetails.client_name }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Invoice No</h5>
                        <div class="case_text">
                            {{ invoiceDetails.invoice_no }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Due Date</h5>
                        <div class="case_text">
                            {{ invoiceDetails.due_date | formatDate }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Case Type</h5>
                        <div class="case_text">
                            {{ invoiceDetails.service_rendered }}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Case ID</h5>
                        <div class="case_text">
                            #{{ invoiceDetails.case_id }}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Note</h5>
                        <div class="case_text">
                            {{ invoiceDetails.comment }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Description
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                                </tr>
                            </thead>
                            <tbody v-if="invoiceDetails.services.length > 0">
                                <tr
                                v-for="(item, i) in invoiceDetails.services"
                                :key="i"
                                >
                                <td>{{ item.description }}</td>
                                <td>${{ item.cost }}</td>
                                </tr>
                            </tbody>
                            <div
                            v-else 
                            class="mt-4"
                            >
                            <v-card
                            flat 
                            width="480"

                            class="d-flex jusity-start align-center"
                            >
                                <v-icon
                                size="50"
                                color="#D4D4D4"
                                class="me-2"
                                >
                                mdi-view-list-outline
                                </v-icon>
                                <span class="placeholder_text">There's currently no service item to display, click the add service button below to add</span>
                            </v-card>
                            </div>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <span class="text_caption me-2">Total</span>
                        <span class="invoice_total display-1">${{ invoiceDetails.amount | amountFormat }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else>
                <v-row>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="6">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="6">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader
                        type="image, table-tfoot"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ApptCard from '../../components/attorney/AppointmentCardAttorney.vue'
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'

  export default {

    components: {
        Sidebar, Navbar, ApptCard
    },

    data () {
      return {
        tab: 0,
        invoicePreview: false,
        invoiceLoaded: false,
        invoiceDetails: {},
        selectedType: 0,
        invoiceStatus: ['all invoice', 'pending', 'cancelled', 'paid'],
        type: '',
        search: '',
        filter: '',
        sort: false,
        page: 1,
        limit: 10,
        totalPages: 3,
        invoiceLoader: false,
      }
    },

    watch: {
        selectedType(v) {
            if(v != 0) {
                this.type = this.invoiceStatus[v]
            } else {
                this.type = ''
            }
            this.getAllInvoices()
        },

        page() {
            this.getAllInvoices()
        },
        sort() {
            this.getAllInvoices()
        },

        search(val) {
            val && val !== this.getAllInvoices(val);
        },
    },

    methods: {
        pendingCard() {
            this.selectedType = 1
        },
        paidCard() {
            this.selectedType = 3
        },
        getAllInvoices(v) {
            this.invoiceLoader = true;
            return this.$store.dispatch('getAllInvoices', {
                status: this.type,
                search: v === undefined ? '' : v,
                page: this.page,
                limit: this.limit,
                sort: this.sort ? 'createdAt,desc' : 'createdAt,asc',
            })
            .then(() => {
                this.invoiceLoader = false;
                // this.totalPages = response.data.totalPages; 
            })
            .catch((error) => {
                this.invoiceLoader = false;
                console.error(error);
                this.snackbar = true;
                this.message = error.response?.data?.error || 'An error occurred';
                this.bgColor = 'error';
            });
        },

        selectedTxn(item) {
            console.log(item)
            this.invoicePreview = true
            this.invoiceDetails = item
            // this.invoiceLoaded = true
            // this.$store.dispatch('getInvoiceById', item.transaction.invoice_id)
            // .then((response) => {
            //     console.log(response)
            //     this.invoiceLoaded= false
            //     this.invoiceDetails = response.data.data.invoice
            // })
            // .catch((error) => {
            //     this.invoiceLoaded= false
            //     this.snackbar = true
            //     this.message = error.response.data.error
            //     this.bgColor = 'error'
            // })
        },

    },

    mounted() {
        this.getAllInvoices()
    },

    computed: {
        invoices() {
            return this.$store.state.invoices;
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }
    .placeholder_text {
        color: #A7A7A7;
    }
    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }
    .card_figure-text {
        color: #023E7D;
    }
    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .attorney_card-box {
        height: 34px;
    }
    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }
    .list_text {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
    }
    .card_lheading {
        color: #023E7D;
    }
    .card_lsubheading {
        color: #585F6C;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }
    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }
    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }
    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }
    .invoice_total {
        font-size: 25px;
        font-weight: 700;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .case_link {
        color: #000;
        font-weight: 400;
    }
</style>