<template>
    <div>
        <v-card 
        class="pa-2 mt-6"
        flat
        color="#023E7D"
        >
            <v-card-title class="card_topic">
                Get started
            </v-card-title>
                <v-card-text>
                    <div class="pb-1">
                        <div class="d-flex justify-start">
                            <h4 class="me-2 card_lheading text-capitalize">Add Bar Details</h4>
                            <v-icon small color="#89d42c">
                                mdi-check-circle
                            </v-icon>
                        </div>
                        <span class="card_lsubheading">
                            <span class="text-capitalize">This enable us verify your account, un-verified account are limited.</span>
                        </span>
                    </div>
                    <div class="pb-1">
                        <div class="d-flex justify-start">
                            <h4 class="me-2 card_lheading text-capitalize">Set Work Schedule</h4>
                            <v-icon small color="#89d42c">
                                mdi-check-circle
                            </v-icon>
                        </div>
                        <span class="card_lsubheading">
                            <span class="text-capitalize">To enable client set appointments and consultations.</span>
                        </span>
                    </div>
                    <div class="pb-1">
                        <div class="d-flex justify-start">
                            <h4 class="me-2 card_lheading text-capitalize">Setup Stripe</h4>
                            <v-icon small color="#89d42c">
                                mdi-check-circle
                            </v-icon>
                        </div>
                        <span class="card_lsubheading">
                            <span class="text-capitalize">We are able direct your legal invoice payment from clients to your account.</span>
                        </span>
                    </div>
                </v-card-text>
        </v-card>
    </div>
</template>

<script>
  export default {

    components: {

    },

    data () {
      return {
        
      }
    },

    created: function () {
    //   this.$store.dispatch('getAttorneyCases')
    },

    methods: {
        
    },

    computed: {
        bothCases() {
            return this.$store.state.bothCases;
        },
    }

  }
</script>

<style scoped>
    .card_topic {
        color: #ffc300;
        font-weight: 600;
        font-size: 20px;
    }
    .card_lheading {
        color: #FFFFFF;
    }
    .card_lsubheading {
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }
    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }
    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }
    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }

    .placeholder_text {
        color: #A7A7A7;
        font-size: 12px;
    }
</style>