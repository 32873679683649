import { render, staticRenderFns } from "./NavbarAttorney.vue?vue&type=template&id=7675fd66&scoped=true&"
import script from "./NavbarAttorney.vue?vue&type=script&lang=js&"
export * from "./NavbarAttorney.vue?vue&type=script&lang=js&"
import style0 from "./NavbarAttorney.vue?vue&type=style&index=0&id=7675fd66&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7675fd66",
  null
  
)

export default component.exports