import eventBus from './EventBus';

export default {
  install(Vue, options) {
    const ws = new WebSocket(options.websocketUrl);

    ws.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      console.log(data);

      if (data.type === "case_creation") {
        console.log('receive create case')
        eventBus.$emit("caseCreationMessage", data);
      }
      if (data.type === "client_match_request") {
        console.log('receive client match')
        eventBus.$emit("clientMatchMessage", data);
      }
      if (data.type === "attorney_match_request") {
        console.log('receive attorney match')
        eventBus.$emit("attorneyMatchMessage", data);
      }
      if (data.type === "⁠attorney_setup") {
        console.log('receive attorney setup')
        eventBus.$emit("attorneySetupMessage", data);
      }
      if (data.type === "case_progress") {
        console.log('receive case progress')
        eventBus.$emit("caseProgressMessage", data);
      }
      if (data.type === "statute_of_limitation") {
        console.log('receive statute of limitation')
        eventBus.$emit("statuteLimitationMessage", data);
      }
      if (data.type === "case_progress_update") {
        console.log('receive ⁠case progress update')
        eventBus.$emit("⁠progressUpdateMessage", data);
      }
      if (data.type === "case_status_update") {
        console.log('receive ⁠case status update')
        eventBus.$emit("⁠caseStatusUpdateMessage", data);
      }
      if (data.type === "client_retained_attorney") {
        console.log('receive client retained attorney')
        eventBus.$emit("clientRetainedAttorneyMessage", data);
      }
      if (data.type === "client_dismissed_attorney") {
        console.log('receive client dismissed attorney')
        eventBus.$emit("clientDismissedAttorneyMessage", data);
      }
      if (data.type === "review_rating") {
        console.log('receive review rating')
        eventBus.$emit("reviewRatingMessage", data);
      }
      if (data.type === "stripe_account_setup") {
        console.log('receive stripe account setup')
        eventBus.$emit("stripeSetupMessage", data);
      }
      if (data.type === "stripe_onboarding") {
        console.log('receive stripe account onboarded')
        eventBus.$emit("stripeOnboardingMessage", data);
      }
      if (data.type === "invoice_payment") {
        console.log('receive invoice payment')
        eventBus.$emit("invoicePaymentMessage", data);
      }
      if (data.type === "boost_creation") {
        console.log('receive boost creation')
        eventBus.$emit("boostCreationMessage", data);
      }
      if (data.type === "stripe_subscription") {
        console.log('receive stripe subscription')
        eventBus.$emit("stripeSubscriptionMessage", data);
      }
      if (data.type === "invoice_creation") {
        console.log('receive invoice creation')
        console.log(data)
        eventBus.$emit("invoiceCreationMessage", data);
      }
      if (data.type === "create_chat") {
        console.log('receive chat')
        eventBus.$emit("chatMessage", data);
      }
      if (data.type === "client_booked_appointment") {
        console.log('receive appointment')
        eventBus.$emit("appointmentMessage", data);
      }
      // if (data.type === "clientId") {
      //   console.log('receive client id')
      //   eventBus.$emit("clientIdMessage", data);
      // }
    });

    // ws.addEventListener('close', (event) => {
    //   console.log('WebSocket connection closed:', event);
    //   eventBus.$emit("refreshDialog", event);
    // });

    // Assign WebSocket instance to Vue prototype
    Vue.prototype.$ws = ws;

  },
};
