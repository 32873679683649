import { render, staticRenderFns } from "./AppointmentCardClient.vue?vue&type=template&id=21d90203&scoped=true&"
import script from "./AppointmentCardClient.vue?vue&type=script&lang=js&"
export * from "./AppointmentCardClient.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentCardClient.vue?vue&type=style&index=0&id=21d90203&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d90203",
  null
  
)

export default component.exports