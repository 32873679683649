import Vue from 'vue';

const eventBus = new Vue();

// Custom EventBus logic
const EventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

// Merging the custom EventBus logic with the Vue instance-based EventBus
Object.assign(eventBus, EventBus);

export default eventBus;