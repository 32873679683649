<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    permanent
    width="200"
    floating
    class="d-none d-sm-none d-md-flex nav_card" 
  >
    <v-list-item class="px-4 mb-6 d-flex align-center">
      <v-list-item-avatar size="25" class="me-2">
        <v-img src="../../assets/legallync-cr-light.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-title class="banner font-weight-bold">LegalLync</v-list-item-title>
    </v-list-item>
    <v-list class="pa-2">
      <v-list-item-group
        v-model="selectedItem"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          router :to="item.route"
          link
          active-class="link"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="21" class="link_icon" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="bottom_link font-weight-medium" v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <div>
        <v-list flat>
          <v-list-item
          link
          @click="logout"
          >
          <v-list-item-icon>
              <v-icon size="21" color="#023E7D">mdi-logout-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
              <v-list-item-title><span class="bottom_link font-weight-medium">Logout</span></v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  export default {
    data () {
      return {
        drawer: true,
        selectedItem: 0,
        items: [
          { title: 'Home', icon: 'mdi-home-outline', route: '/attorney/dashboard' },
          { title: 'Cases', icon: 'mdi-briefcase-outline', route: '/attorney/cases' },
          { title: 'Appointments', icon: 'mdi-calendar-blank-outline', route: '/attorney/appointments' },
          { title: 'Invoice', icon: 'mdi-text-box-outline', route: '/attorney/transactions' },
          { title: 'Settings', icon: 'mdi-cog-outline', route: '/attorney/settings' },
        ],
        mini: true,
      }
    },
    methods: {
      logout() {
        this.$store.dispatch('logout')
      }
    }
  }
</script>

<style scoped>
  .banner {
    color: #023E7D;
  }
  .link_icon {
    color: #023E7D;
  }
  .bottom_link {
    font-size: 14px;
    color: #023E7D;
  }
  .nav_card {
    border-right: 1px solid #e9ecef;
    /* background-color: #FBFBFB; */
  }
  .v-list-item--active {
    background-color: #023E7D;
    color: #FFF;
    border-radius: 5px;
  }
  .v-list-item--active .bottom_link {
    background-color: #023E7D;
    color: #FFF;
    border-radius: 5px;
  }
</style>