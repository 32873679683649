import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import createPersistedState from "vuex-persistedstate";
import cloudinary from '../cloudinaryConfig';

import router from '@/router/index'

Vue.use(Vuex, VueAxios, axios, Router)

const getDefaultState = () => {
  return {
    token: localStorage.getItem('access_token') || null,
    resetToken: localStorage.getItem('refresh_token') || null,
    verifyToken: '',
    verificationEmail: '',
    userData: {},
    resetDetails: {},

    // attorney store
    attorneyCases: {},
    invoices: {},

    // client store
    clientCases: {},

    
    clientAppointments: [],
    plans: [],
    transactions: [],
    txnCount: 0,
    user: {
      id: '',
      role: ''
    },
    pendingTxn: [],
    pendingTxnCount: 0,
    paidTxn: [],
    paidTxnCount: 0,
    pickedPlan: {},
    userDetail: '',
    attorneyCalendar: [],
    notifications: [],
    practiceAreas: [],
    boostData: {},
  };
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    loggedIn(state) {
        return state.token !== null
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    setToken(state, token) {
      state.token = token
    },

    setRefreshToken(state, refreshToken) {
      state.resetToken = refreshToken
    },

    setUserId(state, id) {
      state.user.id = id
    },

    setUserRole(state, role) {
      state.user.role = role
    },

    setVerifyToken(state, verifyToken) {
      state.verifyToken = verifyToken
    },

    setVericationEmail(state, verificationEmail) {
      state.verificationEmail = verificationEmail
    },

    setResetDetails(state, resetDetails) {
      state.resetDetails = resetDetails
    },

    setUserData(state, userData) {
      state.userData = userData
    },

    //attorney state
    setAttorneyCases(state, attorneyCases) {
      state.attorneyCases = attorneyCases;
    },
    setInvoices(state, invoices) {
      state.invoices = invoices;
    },

    //client state
    setClientCases(state, clientCases) {
      state.clientCases = clientCases;
    },


    setBoostData(state, boostData) {
      state.boostData = boostData
    },

    setAllCase(state, allCase) {
      state.allCase = allCase.slice().reverse();
    },

    setPracticeAreas(state, practiceAreas) {
      state.practiceAreas = practiceAreas
    },

    setOpenCount(state, openCount) {
      state.openCount = openCount
    },

    setOpenCases(state, openCases) {
      state.openCases = openCases.slice().reverse();
    },

    setMatchedCount(state, matchedCount) {
      state.matchedCount = matchedCount
    },

    setMatchedCases(state, matchedCases) {
      state.matchedCases = matchedCases.slice().reverse();
    },

    setRetainedCount(state, retainedCount) {
      state.retainedCount = retainedCount
    },

    setRetainedCases(state, retainedCases) {
      state.retainedCases = retainedCases.slice().reverse();
    },

    

    setClientAppointments(state, clientAppointments) {
      state.clientAppointments = clientAppointments
    },

    setAttorneyCalendar(state, attorneyCalendar) {
      state.attorneyCalendar = attorneyCalendar
    },

    setPlans(state, plans) {
      state.plans = plans
    },

    setTransactions(state, transactions) {
      state.transactions = transactions
    },

    setAllTxnCount(state, txnCount) {
      state.txnCount = txnCount
    },

    setPendingTxn(state, pendingTxn) {
      state.pendingTxn = pendingTxn
    },

    setPendingTxnCount(state, pendingTxnCount) {
      state.pendingTxnCount = pendingTxnCount
    },

    setPaidTxn(state, paidTxn) {
      state.paidTxn = paidTxn
    },

    setPaidTxnCount(state, paidTxnCount) {
      state.paidTxnCount = paidTxnCount
    },

    setSelectedPlan(state, item) {
      state.pickedPlan = item
    },

    setUser(state, item) {
      state.userDetail = item
    },

    setNotifications(state, notifications) {
      state.notifications = notifications
    },

    

  },
  actions: {
    logout(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth/logout`,{ headers: headers })
          .then(response => {
            console.log('logged out')
            console.log(response)
            localStorage.removeItem('access_token')
            context.commit('resetState');
            router.push({
              name:'Logout',
            })
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },


    // Post endpoints
    registration(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = { "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('/auth/register', 
          {
            role: data.role,
            image_url: data.img,
            email: data.email,
            password: data.password,
            first_name: data.firstName,
            last_name: data.lastName,
            zipcode: data.zipCode,
            city: data.city,
            state: data.state,
            country: data.country,

            dob: data.dob,
            contact_method: data.contact,
            
            spoken_language: data.languages,
            about: data.about,

            licensed_areas: data.licensedAreas,
            attorney_type: data.typeAttorney,
            practice_area: data.practiceAreas,

            phone: data.phone,
            website: data.website,
            twitter: data.twitter,
            facebook: data.facebook,
            instagram: data.instagram,
            linkedin: data.linkedin,

            contigency: data.contingency,
            hourly_rate: data.hourlyRate,
            fixed_rate: data.fixedRate,
            payment_method: data.paymentMethod
          },
          {
            headers: headers,
          })
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              console.log('success')
              context.commit('setVerifyToken', response.data.data.verify_email)
              context.commit('setVericationEmail', data.email)
              router.push({
                name:'Verification',
              })
              resolve(true)
            }
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    addUserToWaitlist(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = { "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('/auth/waitlist', 
          {
            email: data.email,
            school_name: data.school,
            first_name: data.firstName,
            last_name: data.lastName,
            bar_exam_date: data.dob,
            role: data.role
          },
          {
            headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    userLogin(context, data) {
      return new Promise((resolve, reject) => {
        let headers = { "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('/auth/login', data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              localStorage.setItem('access_token', response.data.token);
              localStorage.setItem('refresh_token', response.data.refresh_token);
              context.commit('setToken', response.data.token)
              context.commit('setRefreshToken', response.data.refresh_token)
            }
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            // console.log(error.response)
            if(error.response.status === 401) {
              context.commit('setVerifyToken', error.response.data.data.verify_email)
              context.commit('setVericationEmail', data.email)
            }
            reject(error)
          })
      })
    },

    forgotPassword(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {"Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/auth/get-otp`, data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            let resetDetails = response.data
            resetDetails.email = data.email
            context.commit('setResetDetails', resetDetails)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    getVerificationOtp(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {"Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/auth/get-otp`, {
            email: context.state.verificationEmail,
            action: data.action,
          }, 
          {
              headers: headers,
          })
          .then(response => {
            context.commit('setVerifyToken', response.data.data.verify_email)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    verifyResetPwd(context, data) {
      console.log(data)
      const headers = { "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json", };
              return new Promise((resolve, reject) => {
              axios.post(`/auth/validate-otp?token=${data.resetTok}`, {
                otp: data.code
              },{ headers: headers })
        .then(response => {
            console.log(response)
            let resetDetails = response.data
            context.commit('setResetDetails', resetDetails)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
      })
    },

    createCase(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('/client/case', {
            case_type: data.types,
            why_need_attorney: data.reason,
            case_practice_area: data.practiceArea,
            case_name: data.caseName,
            date_of_incident: data.date,
            location_incident: data.locationIncident,
            zip_code: data.zip_code,
            case_description: data.caseDetail,
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    setCalendar(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('/calendar/availabilities', {
            consultation_duration: data.consultation_duration,
            Monday: data.Monday,
            Tuesday: data.Tuesday,
            Wednesday: data.Wednesday,
            Thursday: data.Thursday,
            Friday: data.Friday,
            Saturday: data.Saturday,
            Sunday: data.Sunday
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    bookAppointment(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('/calendar/book-as-client', {
            date: data.date,
            from: data.from,
            to: data.to,
            attorney_id: data.attorney_id,
            case_id: data.case_id,
            status: data.status,
            is_consultant: data.is_consultant
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    createBoostAds(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('stripe/create-boost-charge', data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    allAttorneys(context) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post('/client/case/all-attorney', {
            
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    sendLimitation(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/attorney/statute-of-limitation/${data.caseId}`, {
            statute_of_limitation: data.date,
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    sendProgress(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/attorney/case-progress/${data.caseId}`, {
            title: data.title,
            step: data.step,
            notes: data.notes,
            date: data.date
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    createInvoice(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/stripe/create-invoice`, data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    BlockDate(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/calendar/unvailabilities`, data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    BlockDateTime(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/calendar/unvailabilities`, data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    sendMessage(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/chats/${data.chatId}/${data.recipientId}`, {
            message: data.message,
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    createStripeAccount(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/stripe/create-account`, data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    subscriptionToken(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = {"Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/stripe/create-subscription`, data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    sendToken(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = {"Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/stripe/create-card-charge?token=${data.invoice_token}`, {
            token_id: data.token_id,
            invoice_id: data.invoice_id,
            invoice_no: data.invoice_no,
            client_email: data.client_email,
            attorney_email: data.attorney_email,
            amount: data.amount,
            service_rendered: data.service_rendered,
            case_id: data.case_id,
            due_date: data.due_date,
            service_hour: data.service_hour,
            client_name: data.client_name,
            attorney_name: data.attorney_name,
            attorney_id: data.attorney_id,
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    createIntent(context, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.post(`/create-payment-intent`, data, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    imgCloudUpload(context, file) {
      var cloudinary_url = 'https://api.cloudinary.com/v1_1/dwkagby2h/upload';
      var cloudinary_upload_preset = 'pjkj2trc';
      var formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', cloudinary_upload_preset);

      return new Promise((resolve, reject) => {
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
          axios.post(cloudinary_url, formData,
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            const publicId = response.data.public_id;
            const transformedImageUrl = cloudinary.url(publicId, {
            transformation: [
                { quality: "auto:low" },
                { aspect_ratio: "16:9", crop: "fill" }
            ]
            });

            console.log('Transformed Image URL:', transformedImageUrl);
            resolve(transformedImageUrl)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },



    // Put endpoints
    verifyEmail(context, data) {
      console.log(data)
      const headers = { "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json", };
              return new Promise((resolve, reject) => {
              axios.put(`/auth/verify-email?token=${context.state.verifyToken}`, {
                otp: data.code
              },{ headers: headers })
        .then(response => {
            console.log(response)
            context.commit('setUserId', response.data.user_id)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
      })
    },

    updatePassword(context, data) {
      console.log(data)
      const headers = { "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json", };
              return new Promise((resolve, reject) => {
              axios.put(`/auth/reset-password?token=${data.updateTok}`, {
                password: data.password
              },{ headers: headers })
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
      })
    },

    async updateReadNotification(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
              return new Promise((resolve, reject) => {
              axios.put(`/notifications/${data.id}?is_read=${data.is_read}`, {
              },{ headers: headers })
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
      })
    },

    async updateCaseStatus(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
              return new Promise((resolve, reject) => {
              axios.put(`/attorney/case-status/${data.caseId}`, {
                status: "completed"
              },{ headers: headers })
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
      })
    },

    async updateUnAvailabilties(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
              return new Promise((resolve, reject) => {
              axios.put(`/calendar/unvailabilities`, data,{ headers: headers })
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
      })
    },

    async submitRating(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
              return new Promise((resolve, reject) => {
              axios.put(`/client/case/${data.caseId}/${data.attorneyId}/add-rating-and-review`, {
                rating: data.rating,
                review: data.review
              },{ headers: headers })
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
      })
    },

    async sendClientRequest(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.put(`/client/case/${data.caseId}/${data.attorneyId}/send-match-request`, {}, { headers: headers })
          .then(response => {
            // context.commit('setCases', response.data.data)
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async updateAttorneyProfile(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.put(`/auth/update-profile`, data, { headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async updateClientProfile(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.put(`/auth/update-profile`, data, { headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async acceptAttorneyRequest(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.put(`/client/case/${data.caseId}/${data.attorneyId}/accept-match-request`, {},{ headers: headers })
          .then(response => {
            // context.commit('setCases', response.data.data)
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async sendAttorneyRequest(context, caseId) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.put(`/attorney/case/${caseId}/send-match-request`, {

          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    async acceptClientRequest(context, caseId) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.put(`/attorney/case/${caseId}/accept-match-request`, {

          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    async retainAttorney(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.put(`/client/case/${data.case_id}/${data.attorney_id}/retain-attorney`, {

          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    async dismissAttorney(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.put(`/client/case/${data}/dismiss-retained-attorney`, {

          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    async editCaseProgress(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.put(`/attorney/case-progress/${data.id}`, {
            step: data.step,
            notes: data.notes,
            title: data.title,
            date: data.date
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    async checkCaseProgress(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.put(`/attorney/case-progress/${data.id}`, {
            step: data.step,
            is_completed: data.is_completed,
          }, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },






    // Get endpoints
    async getUser(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth`,{ headers: headers })
          .then(response => {
            context.commit('setUserData', response.data.data)
            const userRole = response.data.data.user.role
            if(userRole === 'client') {
              setTimeout(() => {
                router.push({
                  path: '/client/dashboard'
                })
              }, 3000);
            }
            if(userRole === 'attorney') {
              setTimeout(() => {
                router.push({
                  path: '/attorney/dashboard'
                })
              }, 3000);
            }
            
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    attorneysAds(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.get(`/client/ads/attorneys?type=${data.type}&area=${data.area}&rating=${data.rating}&is_profile_verified=${data.vetStatus}&search=${data.search}`, 
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    updateStripe(context, data) {
      return new Promise((resolve, reject) => {
        let headers = {Authorization: `Bearer ${context.state.token}`, "Access-Control-Allow-Origin" : "*", "Content-type": "Application/json"};
          axios.get(`/stripe/successfull-onboarding?account_id=${data.account_id}`,
          {
              headers: headers,
          })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            reject(error)
          })
      })
    },

    async getUserDetails(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth`,{ headers: headers })
          .then(response => {
            context.commit('setUserData', response.data.data)
            
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getAvailableTimes(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/calendar/availability?date=${data.date}&attorney_id=${data.attorneyId}`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getBoostDetails(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get('stripe/get-attorney-boosts',{ headers: headers })
          .then(response => {
            console.log(response)
            console.log('get boost')
            context.commit('setBoostData', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getCities(context, data) {
      console.log(data)
      let headers = { "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth/zip-codes/primary-city?key_word=${data}`,{ headers: headers })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getZipLocation(context, data) {
      console.log(data)
      let headers = { "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth/zip-codes/primary-city?key_word=${data}`,{ headers: headers })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getStates() {
      let headers = { "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth/states`,{ headers: headers })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getZipCodes(context, data) {
      console.log(data)
      let headers = { "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth/zip-codes?primary_city=${data.primary_city}&state=${data.state}`, { headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getPractice(context) {
      let headers = { "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/auth/practice-areas`,{ headers: headers })
          .then(response => {
            console.log(response)
            context.commit('setPracticeAreas', response.data.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getClientCases(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/client/all-cases?status=${data.status}&select=&sort=${data.order}&page=${data.page}&limit=${data.limit}&search=${data.search}`,{ headers: headers })
          .then(response => {
            console.log('setClientCases')
            console.log(response)
            context.commit('setClientCases', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getCaseById(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/client/case/${data}`,{ headers: headers })
          .then(response => {
            console.log(response)
            
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getInvoiceById(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/stripe/get-invoice/${data}`,{ headers: headers })
          .then(response => {
            console.log(response)
            
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getAttorneyCases(context, data) {
      console.log(data)
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/attorney/cases?status=${data.status}&search=${data.search}&page=${data.page}&limit=${data.limit}&order=${data.order}`,{ headers: headers })
          .then(response => {
            context.commit('setAttorneyCases', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getAttorneyById(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/client/case/attorney-profile/${data}`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getAvailability(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/calendar/availabilities`,{ headers: headers })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    async getUnavailability(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get('/calendar/unvailabilities',{ headers: headers })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    async getBookableDates(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/calendar/get-block-dates?year=${data.year}&month=${data.month}&attorney_id=${data.attorneyId}`,{ headers: headers })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    async getAttorneyAvailability(context, attorneyId) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/calendar/availabilities/${attorneyId}`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getClientAppointments(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/calendar/appointments`,{ headers: headers })
          .then(response => {
            console.log('setClientAppointments')
            console.log(response)
            context.commit('setClientAppointments', response.data.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getAttorneyCalendar(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/calendar/attorney/all-bookings?calendar_view_type=${data.viewType}&input_date=${data.date}`,{ headers: headers })
          .then(response => {
            console.log('setAttorneyCalendar')
            console.log(response)
            context.commit('setAttorneyCalendar', response?.data?.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async attorneyGetCaseById(context, caseId) {
      console.log(caseId)
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/attorney/case/${caseId}`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getInvoiceDetails(context, data) {
      let headers = { Authorization: `Bearer ${data.invoiceToken}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/stripe/get-invoice/${data.invoiceId}`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getInvoiceByCaseId(context, caseId) {
      console.log(caseId)
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/stripe/get-invoice-by-case-id/${caseId}`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    
    async getNotification(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/notifications`,{ headers: headers })
          .then(response => {
            console.log(response)
            context.commit('setNotifications', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    
    async getChatUsers(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/chats`,{ headers: headers })
          .then(response => {
            console.log('getChatUsers')
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getAllInvoices(context, data) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/stripe/get-all-invoices?page=${data.page}&limit=${data.limit}&sort=${data.sort}&status=${data.status}&search=${data.search}`, 
          { headers: headers })
          .then(response => {
            console.log('setInvoices')
            console.log(response)
            context.commit('setInvoices', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getPlans(context) {
      let headers = { "Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/stripe/get-plans`,{ headers: headers })
          .then(response => {
            console.log(response)
            context.commit('setPlans', response.data.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getStripeAcct(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.get(`/stripe/get-account`,{ headers: headers })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    


    //delete endpoints 
    async cancelAppointment(context, appointmentId) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.delete(`/calendar/delete-as-client/${appointmentId}`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    async deleteStripeSetup(context) {
      let headers = { Authorization: `Bearer ${context.state.token}`,"Access-Control-Allow-Origin" : "*",
              "Content-type": "Application/json"};
      return new Promise((resolve, reject) => {
          axios.delete(`/stripe/delete-account`,{ headers: headers })
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },


    
  },
  modules: {

  }
})
