<template>
    <div class="page">
      <v-icon size="100" color="#023E7D" class="icon"
        >mdi-radio-tower</v-icon
      >
      <h1 class="message-title">Oops! No Internet Connection.</h1>
      <p class="message-body">
        Please check your network settings and try again. If you continue to
        experience issues, contact your internet service provider.
      </p>
      <v-btn rounded dark color="#023E7D" elevation="0" class="action-btn" @click="retryConnection">
        Retry
        <v-icon
        right
        dark
        >
            mdi-refresh
        </v-icon>
        </v-btn
      >
    </div>
</template>
    
<script>
  export default {
    name: "OfflinePage",
    methods: {
      retryConnection() {
        location.reload(); // retry by reloading the page
      },
    },
  };
</script>
    
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Arvo:wght@400;700&display=swap');

.page {
    font-family: 'Arvo', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Raleway', sans-serif;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.icon {
    color: red;
}

.message-title {
    margin-top: 20px;
    font-size: 24px;
    color: #333;
}

.message-body {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
    max-width: 500px;
}

.action-btn {
    margin-top: 2rem;
}
</style>
    