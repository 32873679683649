<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <a
                
                class="form_link"
                @click="dialog = true"
                v-on="on"
            >
                conflict waiver.
            </a>
            </template>
            Open conflict waiver
        </v-tooltip>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-card-title>Conflict Waiver</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 400px;">
            <ol>
                <li>You have expressed an interest in opening a case in LegalLync in hopes to obtain legal representation. To do so you are voluntary disclosing information regarding
your legal issue to be viewed by multiple attorneys. You also may discuss with those attorneys the possibility of them representing you in connection with your case. </li>
                <li>You might consult with multiple attorneys and it is therefore possible that you
may decide not to retain many of them in the matter. You and any consulting
lawyer are under no obligation to go beyond the first consultation.</li>
                <li>The attorneys you do not retain understand fully your desire to proceed in that
manner. You, in turn, understand and agree that no attorney-client relationship
will exist unless and until you decide to retain a particular lawyer, the lawyer
agrees to represent you in this matter, and an appropriate engagement letter has
been executed reflecting the terms of the representation.</li>
                <li>You also agree that nothing occurring in any consultation(s) will be used to
prevent any lawyer or law firm from future representation of others adverse to
you if you do not retain that lawyer for law firm.</li>
            </ol>
          </v-card-text>
        </v-card>
      </v-dialog>
    </span>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
      }
    },
  }
</script>

<style scoped>
.form_link {
    color: #023E7D;
    font-weight: bold;
    text-decoration: none;
}
li {
    margin-top: 20px;
}
</style>