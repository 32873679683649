<template>
    <div>
        <v-card 
        class="pa-2 mt-12"
        flat
        color="#F5F5F5"
        >
            <v-card-title class="d-flex justify-end">
                <router-link to="/client/appointments" class="body_llink">
                    Upcoming Appointment
                    <v-icon
                        right
                        small
                        color="#023E7D"
                    >
                        mdi-chevron-right
                    </v-icon>
                </router-link>
            </v-card-title>
            <div v-if="clientAppointments?.length > 0">
                <v-card-text 
                v-for="(item, i) in clientAppointments.slice(0,1)"
                :key="i"
                class="d-flex justify-start"
                >
                    <v-avatar 
                    color="#E0E0E0"
                    rounded
                    class="me-4"
                    >
                    <v-icon color="#023E7D">
                        mdi-calendar-blank-outline
                    </v-icon>
                    </v-avatar>
                    <div
                    >
                        <h4 class="card_lheading">Appointment with <span class="text-capitalize">{{item.attorney_full_name}}</span></h4>
                        <span class="card_lsubheading">{{item.from | resetTime}} {{item.date | formatDate}}</span>
                    </div>
                </v-card-text>
            </div>
            <div v-else>
                <v-card-text>
                    <v-icon size="30" color="#D4D4D4">
                        mdi-calendar-plus
                    </v-icon>
                    <p class="placeholder_text">There's no upcoming appointment available, to create one click the upcoming appointment button above.</p>
                </v-card-text>
            </div>
        </v-card>
    </div>
</template>

<script>
  export default {

    components: {

    },

    data () {
      return {
        
      }
    },

    created: function () {
      this.$store.dispatch('getClientAppointments')
    },

    methods: {
        
    },

    computed: {
        clientAppointments() {
            let appointments = this.$store.state.clientAppointments
            return appointments.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        },

        currentDate() {
            var date = (new Date(Date.now())).toISOString()
            var newDate = `${date.split("T")[0]}T`
            return newDate
        },
    }

  }
</script>

<style scoped>
    .card_lheading {
        color: #023E7D;
    }

    .card_lsubheading {
        color: #585F6C;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }

    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }

    .placeholder_text {
        color: #A7A7A7;
        font-size: 12px;
    }
</style>