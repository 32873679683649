<template>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              :loading="calendarLoader"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="calendarCurrentView('day')">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="calendarCurrentView('week')">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="calendarCurrentView('month')">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              max-width="400px"
              flat
            >
              <v-card-text :class="selectedEvent.name">
                <h3 v-if="selectedEvent.status != 'blocked'" class="app_title text-capitalize">{{ selectedEvent.eventType }} {{ selectedEvent.name }} with {{ selectedEvent.clientName }}</h3>
                <h3 v-else class="app_title text-capitalize">{{ selectedEvent.name }}</h3>
                <div class="mt-2" v-if="selectedEvent.status != 'blocked'">
                  <div>
                    <p>This <span class="text-lowercase">{{ selectedEvent.name }}</span>  was set by {{ selectedEvent.clientName }} in relation to case name<b> {{ selectedEvent.caseName }}</b></p>
                  </div>
                  <v-chip
                    class="me-2 mb-2"
                    dark
                    rounded
                    color="#caf0f8"
                    :text-color="selectedEvent.color"
                  >
                    <v-icon  small class="me-2 ">mdi-calendar-text-outline</v-icon>
                    <b>On {{ selectedEvent.date | formatDate}} </b>
                  </v-chip>
                  <v-chip
                    class="me-2 mb-2"
                    dark
                    rounded
                    color="#caf0f8"
                    :text-color="selectedEvent.color"
                  >
                    <v-icon  small class="me-2 ">mdi-clock-outline</v-icon>
                    <b>Time: {{ selectedEvent.from | resetTime }}</b>
                  </v-chip>
                  <v-chip
                    dark
                    rounded
                    color="#caf0f8"
                    class="mb-2"
                    :text-color="selectedEvent.color"
                  >
                    <v-icon  small class="me-2 ">mdi-timelapse</v-icon>
                    <b>{{ appointmentDuration }} {{ appointmentDuration != 60 ? 'minutes' : 'hours' }}</b>
                  </v-chip>
                </div>
                <div v-else>
                  <p>On {{ selectedEvent.date | formatDate}}</p>
                  <v-chip
                    dark
                    rounded
                    outlined
                    :color="selectedEvent.color"
                  >
                    <v-icon  small class="me-2 ">mdi-cancel</v-icon>
                    <b>{{ selectedEvent.from | resetTime }} - {{ selectedEvent.to | resetTime }}</b>
                  </v-chip>
                </div>
                <div v-show="selectedEvent.status != 'blocked' && selectedEvent.eventType != 'past'" class="text-end">
                  <v-btn
                  small
                  dark
                  :color="selectedEvent.color"
                  class="mt-2"
                  elevation="0"
                  @click="initialMeeting(selectedEvent.chat[0])"
                  >
                  Start Meeting
                  <v-icon
                      right
                      small
                      dark
                  >
                      mdi-open-in-new
                  </v-icon>
                  </v-btn>
                </div>
                <div v-show="selectedEvent.status == 'blocked'" class="mt-2 calendar_caption">{{selectedEvent.activelyBlocked ? blockedNote : scheduledNote}}</div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
</template>

<script>
import moment from 'moment-timezone'

  export default {
    data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      currentDate: '',
      scheduledNote: "It looks like you haven't scheduled any work for this day.",
      blockedNote: "You have blocked off this day in your calendar, indicating that you are unavailable for work.",
      calendarLoader: false
    }),
    mounted () {
      this.currentDate = moment().format("YYYY-MM-D")
      this.getCalendarData(this.currentDate);
      this.$refs.calendar.checkChange();
    },
    watch: {
      attorneyCalendar() {
        setTimeout(() => {
          this.updateRange();
          this.calendarLoader = false
        }, 2000);
      }
    },
    methods: {
      initialMeeting(item) {
        console.log(item)
        let chat_object = item
        this.$router.push({
            name: 'AttorneyMeet',
            params: {
            displayName: chat_object.display_name,
            roomName: chat_object.room_name,
            email: chat_object.email,
            password: chat_object.password,
            }
        });
      },

      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.value;
            this.getCalendarData(this.currentDate);
        });
      },

      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.start;
            this.getCalendarData(this.currentDate);
        });
      },
      prev () {
        this.$refs.calendar.prev()
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.value;
            this.getCalendarData(this.currentDate);
        });
      },
      next () {
        this.$refs.calendar.next()
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.value;
            this.getCalendarData(this.currentDate);
        });
      },
      calendarCurrentView(v) {
        this.type = v
        this.$nextTick(() => {
          this.currentDate = this.$refs.calendar.value;
          if(this.currentDate != '') {
            this.getCalendarData(this.currentDate);
          } else {
            this.currentDate = moment().format("YYYY-MM-D")
            this.getCalendarData(this.currentDate);
          }
        });
      },

      getCalendarData(v) {
        this.calendarLoader = true
        let calendarView = this.$refs.calendar.type
        this.$store
        .dispatch("getAttorneyCalendar", {
          date: v,
          viewType: calendarView,
        })

      },
      
      updateRange () {
        const events = []
        for (let i = 0; i < this.attorneyCalendar.length; i++) {

          const startParsedDate = new Date(this.attorneyCalendar[i].date);

          let minTimeFrom = this.attorneyCalendar[i].from;
          if(minTimeFrom.length === 5) {
            minTimeFrom += ":00";
          }
          const minForm = startParsedDate.toISOString().split("T")[0];
          const minDateTimeFrom = `${minForm + 'T' + minTimeFrom}`;
          const min = new Date(minDateTimeFrom)
          const minUTC = moment(String(min)).format('YYYY-MM-DD hh:mm')

          let minTimeTo = this.attorneyCalendar[i].to;
          if (minTimeTo.length === 5) {
              minTimeTo += ":00";
          }
          const maxTo = startParsedDate.toISOString().split("T")[0];
          const maxDateTimeTo = `${maxTo + 'T' + minTimeTo}`;
          const max = new Date(maxDateTimeTo)
          const maxUTC = moment(String(max)).format('YYYY-MM-DD hh:mm')
          
          const client_id = this.attorneyCalendar[i]?.client_id
          const case_id = this.attorneyCalendar[i]?.case_id
          const date = this.attorneyCalendar[i]?.date
          const status = this.attorneyCalendar[i]?.status
          const event_type = this.attorneyCalendar[i]?.event_type
          const client_full_name = this.attorneyCalendar[i]?.client_full_name
          const case_name = this.attorneyCalendar[i]?.case_name
          let title = ''
          let color = ''
          if(!this.attorneyCalendar[i].is_actively_blocked && this.attorneyCalendar[i].status == 'blocked') {
            title = 'Non-Scheduled'
            color = '#adb5bd'
          } else if(this.attorneyCalendar[i].is_actively_blocked && this.attorneyCalendar[i].status == 'blocked') {
            title = 'Blocked'
            color = '#6c757d'
          } else if(this.attorneyCalendar[i].event_type != 'upcoming' && this.attorneyCalendar[i].status == 'booked') {
            title = 'Appointment'
            color = '#0077b6'
          } else if(this.attorneyCalendar[i].is_consultant && this.attorneyCalendar[i].status == 'booked') {
            title = 'Consultation'
            color = '#3a0ca3'
          } else {
            title = 'Appointment'
            color = '#023E7D'
          }
          const actively_blocked = this.attorneyCalendar[i].is_actively_blocked
          const chat_object = this.attorneyCalendar[i].chat_object;
          const spoken_language = this.attorneyCalendar[i].spoken_language



          events.push({
            name: title,
            activelyBlocked: actively_blocked,
            status: status,
            start: minUTC,
            end: maxUTC,
            from: minTimeFrom,
            to: minTimeTo,
            date: date,
            timed: true,
            caseId: case_id,
            clientId: client_id,
            chat: chat_object,
            language: spoken_language,
            color: color,
            eventType: event_type,
            clientName: client_full_name,
            caseName: case_name,
          })
        }

        this.events = events
      },

      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },

      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },

    computed: {
      attorneyCalendar() {
        return this.$store.state.attorneyCalendar.calendars.data;
      },

      appointmentDuration() {
        return this.$store.state.attorneyCalendar.consultation_duration;
      },
    }
  }
</script>

<style scoped>
.app_title {
  font-size: 18px;
  font-weight: 600;
}
.Non-Scheduled {
  border-left: 4px solid #adb5bd;
}
.Blocked {
  border-left: 4px solid #6c757d;
}
.Appointment {
  border-left: 4px solid #023E7D;
}
.Consultation {
  border-left: 4px solid #3a0ca3;
}
.calendar_caption {
  font-size: 13px;
  color: grey;
  width: 300px;
}
</style>