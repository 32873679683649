<template>
  <div>
    <!-- <v-btn
    small
    color="#023E7D"
    text
    @click="wscall"
    >
    Test Case
    </v-btn> -->
    <v-btn
    small
    color="#023E7D"
    text
    @click="dialog = true"
    >
    <v-icon
        left
        small
    >
        mdi-plus-circle-outline
    </v-icon>
    Create Case
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="700"
    >
      <v-card>
        <div class="title_bg">
            <v-card-title class="card_title font-weight-bold">
            New Case
            </v-card-title>
        </div>
        <v-card-text>
            <v-stepper class="elevation-0" v-model="e1">
                <v-stepper-header class="elevation-0">
                <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                    color="#023E7D"
                >
                    Attorney details and reason
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                    color="#023E7D"
                >
                    About your case
                </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                <v-stepper-content step="1">
                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    >
                    <v-card
                    class="mb-12"
                    flat
                    min-height="200px"
                    >
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <p>What type of case is this ?</p>
                                    <v-autocomplete
                                    v-model="types"
                                    :items="attorneyType"
                                    label="Select case type"
                                    :rules="[rules.required]"
                                    filled
                                    hide-details="auto"
                                    class="some-style"
                                    >
                                    <template v-slot:selection="{ item }">
                                        <span
                                        class="text-capitalize"
                                        >
                                        {{ item }}
                                        </span>
                                    </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6" v-show="types === 'Civil Defense' || types === 'Criminal Defense'">
                                    <div class="check_container">
                                        <v-checkbox 
                                        v-model="reasonCheck"
                                        color="#023E7D"
                                        dense
                                        readonly
                                        value="being sued"
                                        hide-details="auto"
                                        >
                                            <template v-slot:label>
                                                <div class="font-weight-medium check_text">
                                                I'm being sued
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </div>
                                </v-col>
                                <v-col cols="6" v-show="types === 'Civil Plaintiff'">
                                    <div class="check_container">
                                        <v-checkbox 
                                        v-model="reasonCheck"
                                        color="#023E7D"
                                        dense
                                        readonly
                                        value="sueing someone"
                                        hide-details="auto"
                                        >
                                            <template v-slot:label>
                                                <div class="font-weight-medium check_text">
                                                I'm sueing someone
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </div>
                                </v-col>
                                <!-- <v-col cols="12" v-show="reasonCheck === 'other'">
                                    <v-text-field
                                    v-model="reason"
                                    label="I need an attorney for?"
                                    filled
                                    hide-details="auto"
                                    dense
                                    class="some-style"
                                    ></v-text-field>
                                </v-col> -->
                                <v-col lg="12">
                                <v-autocomplete
                                    v-model="areas"
                                    :items="practiceAreas"
                                    label="Select practice area"
                                    :item-text="item => item.name"
                                    :rules="[rules.objRequired]"
                                    filled
                                    multiple
                                    return-object
                                    hide-details="auto"
                                    class="some-style"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                        <span
                                        v-if="index === 0"
                                        class="grey--text body-2"
                                        >
                                        {{ areas.length  }} selected
                                        </span>
                                    </template>
                                    </v-autocomplete>
                                    <v-chip
                                    v-for="(item, i) in areas"
                                    :key="i"
                                    class="mt-2 me-2"
                                    small
                                    close
                                    color="#023E7D"
                                    text-color="white"
                                    @click:close="removeArea(areas, item)"
                                    >
                                    {{item.name}}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" @click="dialog = false" text>
                        Cancel
                        </v-btn>
                        <v-btn
                        color="#023E7D"
                        class="stepper_btn"
                        depressed
                        @click="nextStep"
                        >
                        Next
                        </v-btn>
                    </div>
                </v-form>  
                    
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-form
                    ref="form2"
                    v-model="valid2"
                    lazy-validation
                    >
                    <v-card
                    class="mb-12"
                    flat
                    min-height="200px"
                    >
                        <v-card-text>
                            <p>Tell us about your case?</p>
                            <v-row>
                                <v-col lg="6">
                                    <v-text-field
                                    v-model="caseName"
                                    label="Case Name"
                                    :rules="[rules.required]"
                                    filled
                                    dense
                                    hide-details="auto"
                                    class="some-style"
                                    ></v-text-field>
                                </v-col>
                                <v-col lg="6">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            label="Date of incident"
                                            append-icon="mdi-calendar-blank-outline"
                                            :rules="[rules.required]"
                                            filled
                                            dense
                                            readonly
                                            hide-details="auto"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="some-style"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="date"
                                        no-title
                                        scrollable
                                        :max="new Date().toISOString().substr(0, 10)"
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col lg="6">
                                    <v-autocomplete
                                    v-model="zipCode"
                                    label="Incident Zip Code"
                                    :rules="[rules.required]"
                                    @keyup="querySelections"
                                    return-object
                                    type="number"
                                    item-text="zip_code"
                                    filled
                                    :items="zipCodeList"
                                    :loading="isLoading"
                                    dense
                                    class="some-style"
                                    hide-details="auto"
                                    @change="zipCodeSelected"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field
                                    v-model="locationIncident"
                                    label="Incident City, State"
                                    :rules="[rules.required]"
                                    readonly
                                    hide-details="auto"
                                    filled
                                    dense
                                    class="some-style"
                                    ></v-text-field>
                                </v-col>
                                <v-col lg="12">
                                    <v-textarea
                                    v-model="caseDetail"
                                    filled
                                    label="Case Details"
                                    :rules="[rules.required]"
                                    auto-grow
                                    height="80"
                                    hide-details="auto"
                                    class="some-style"
                                    ></v-textarea>
                                    <v-checkbox dense hide-details="auto" color="#023E7D" :rules="[rules.required]" v-model="waiverCheckbox">
                                    <template v-slot:label>
                                        <div>
                                        I confirm that I have read to the 
                                            <Waiver />
                                        </div>
                                    </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        
                    </v-card>
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" @click="e1 = 1" text>
                        Back
                        </v-btn>
                        <v-btn
                        color="#023E7D"
                        class="stepper_btn"
                        depressed
                        :loading="loading"
                        @click="newCase"
                        >
                        Continue
                        </v-btn>
                    </div>
                    </v-form>
                </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card-text>
        <v-card-actions>
          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snack bar -->
    <v-snackbar
    v-model="snackbar"
    :color="bgcolor"
    dark
    right
    >
    {{ message }}
    <template v-slot:action="{ attrs }">
        <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click="snackbar = false"
        >
        <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
    </template>
    </v-snackbar>
  </div>
</template>

<script>
import Waiver from '../../components/Waiver.vue';

  export default {
    components: {
        Waiver
    },
    
    data () {
      return {
        dialog: false,
        e1: 1,
        loading: false,
        isLoading: false,
        zipLoading: false,
        snackbar: false,
        bgcolor: '',
        message: '',
        attorneyType: ['Criminal Defense', 'Civil Plaintiff', 'Civil Defense', 'Transaction'],
        types: '',
        areas: [],
        caseName: '',
        reasonCheck: '',
        reason: '',
        locationIncident: null,
        zipCode: '',
        caseDetail: '',
        waiverCheckbox: '',
        menu: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        cityList: [],
        citySearch: null,
        zipCodeList: [],
        newMessage: "",
        messages: [],
        wsReady: false, // Track WebSocket readiness
        rules: {
          required: value => !!value || 'Required.',
          objRequired: v => Boolean(Object.keys(v || {})[0]) || "Required.",
        },
        location: {},
        valid: true,
        valid2: true,
      }
    },

    created: function () {
        // this.getPractice()
        // this.getCities();
        // this.$store.dispatch('getClientCases');
    },

    watch: {
        types(val) {
            if(val == 'Civil Plaintiff') {
                this.reasonCheck = 'sueing someone'
            } else {
                this.reasonCheck = 'being sued'
            }
        },

        citySearch (val) {
            val && val !== this.locationIncident && this.querySelections(val)
        },
    },

    methods: {
        // wscall() {
        //     // if (!this.wsReady) return false; // Check if WebSocket is ready

        //     this.$ws.send(JSON.stringify({ type: "create_case", data: this.newMessage }));
        //     this.newMessage = "";
        // },

        nextStep() {
            let formstatus = this.$refs.form.validate()
            if(formstatus != true) return
            this.e1 = 2
        },
            
        removeType(types, item) {
            var index = types.indexOf(item);
            if (index > -1) {
                types.splice(index, 1);
            }
            return types;
        },

        removeArea(areas, item) {
            var index = areas.indexOf(item);
            if (index > -1) {
                areas.splice(index, 1);
            }
            return areas;
        },

        newCase() {
            let formstatus = this.$refs.form2.validate()
            if(formstatus != true) return
            this.loading = true
            if(this.reasonCheck != 'other') {
                this.reason = this.reasonCheck
            }
            var areasNames = this.areas.map(function(item) {
                return item['name'];
            });
            this.$store.dispatch('createCase', {
            types: this.types,
            practiceArea: areasNames,
            reason: this.reason,
            caseName: this.caseName,
            date: this.date,
            locationIncident: this.locationIncident,
            zip_code: this.location.zip_code,
            caseDetail: this.caseDetail
            })
            .then(() => {
            this.loading = false
            this.snackbar = true
            this.message = 'Case created successfully'
            this.bgcolor = 'success'
            this.dialog = false
            this.$store.dispatch('getClientCases');
            // this.wscall()

            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgcolor = 'error'
            })
        },

        // getPractice() {
        //     this.loading = true
        //     this.$store.dispatch('getPractice', {
        //     })
        //     .then((response) => {
        //     this.loading = false
        //     this.practiceList = response.data.data
        //     })
        //     .catch((error) => {
        //     this.loading = false
        //     this.snackbar = true
        //     this.message = error.response.data.error
        //     this.bgcolor = 'error'
        //     })
        // },

        querySelections(v) {
            this.isLoading = true

            this.$store.dispatch('getCities', v.srcElement._value)
            .then((response) => {
                this.isLoading = false
                this.zipCodeList = response.data.data
            })
            .catch((error) => {
                this.isLoading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        zipCodeSelected() {
            this.location = this.zipCode
            this.locationIncident = `${this.zipCode.primary_city}, ${this.zipCode.state}`
        },

        getZipCodes() {
            this.zipLoading = true
            this.$store.dispatch('getZipCodes', this.locationIncident)
            .then((response) => {
            this.zipLoading = false
            this.zipCodeList = response.data.data
            })
            .catch((error) => {
            this.zipLoading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgcolor = 'error'
            })
        }
    },

    computed: {
        practiceAreas() {
            return this.$store.state.practiceAreas;
        },
    }
  }
</script>

<style scoped>

.title_bg {
    background-color: #023E7D;
    color: #fff;
}

.stepper_btn {
    color: #fff;
}

.some-style >>> .v-input__slot::before {
    border-style: none !important;
}

.check_container {
    border: solid 1px #023E7D;
    border-radius: 3px;
    background-color: #F6FAFF;
    padding: 5px 10px;
    height: 52px;
}

.check_text {
    color: #023E7D;
}

.form_link {
    color: #023E7D;
    font-weight: bold;
    text-decoration: none;
}

</style>